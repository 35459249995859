import styled, { css } from 'styled-components'

import { Popover as PopoverMiu } from '@material-ui/core'

export const LabelAccordion = styled.div`
  display: flex;
  width: 100%;
`

export const Title = styled.div`
  padding-right: 24px;
  word-break: break-word;
  display: grid;
`

export const Container = styled.div`
  height: 100%;
  font-family: Roboto, sans-serif;

  & + & {
    margin-top: 18px;
  }
`

export const WarningContainer = styled.div`
  padding: 24px 24px 20px 24px;
`

export const WarningActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 12px;
`

export const WarningDeleteText = styled.div`
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgb(53, 59, 96);
`

export const Popover = styled(PopoverMiu)`
  & .MuiPaper-root {
    box-shadow: 0px 9px 30px rgba(53, 59, 96, 0.3);
    border-radius: 4px;
  }
`

export const PopoverStatus = styled(PopoverMiu)`
  & .MuiPaper-root {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
`

export const StatusMenu = styled.div`
  padding: 7px 0;
`

type StatusMenuItemProps = {
  selected?: boolean
}

export const StatusMenuItem = styled.div`
  padding: 6px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
  ${(props: StatusMenuItemProps) =>
    props.selected &&
    css`
      background: #f4f4f8;
      cursor: default;
    `}

  &:hover {
    background: #f4f4f8;
  }
`

type ChangeStatusIssueRowProps = {
  disabled?: boolean
  clickable?: boolean
}

export const ChangeStatusIssueRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 700;
  letter-spacing: 0.15px;
  color: #000335;
  flex: 2;
  transition: 0.2s ease-out color;

  svg path {
    transition: 0.2s ease-out fill;
  }

  &:hover {
    ${(props: ChangeStatusIssueRowProps) =>
      props.clickable &&
      css`
        color: #4c5ecf;

        svg path {
          fill: #4c5ecf;
        }
      `}
  }

  &:focus {
    ${(props: ChangeStatusIssueRowProps) =>
      props.clickable &&
      css`
        color: #2235aa;

        svg path {
          fill: #2235aa;
        }
      `}
  }

  ${(props: ChangeStatusIssueRowProps) =>
    props.disabled &&
    css`
      pointer-events: none;
      color: rgba(53, 59, 96, 0.2);
      cursor: default;

      path {
        fill: rgba(53, 59, 96, 0.2);
      }
    `}

  ${(props: ChangeStatusIssueRowProps) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`

type StatusIndicatorProps = {
  color: string
}

export const StatusIndicator = styled.div`
  height: 18px;
  width: 4px;
  border-radius: 10px;
  background-color: ${(props: StatusIndicatorProps) => props.color};
`

export const UploadedFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
`

export const AttachedFilesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`

export const StatusValue = styled.div`
  white-space: nowrap;
  font-weight: 700;
  color: #000335;
  font-size: 14px;
`

export const StatusWrapper = styled.div`
  margin-left: auto;
  width: min-content;
  display: flex;
  align-items: center;
  margin-right: 20px;
`

export const Description = styled.div`
  max-height: 300px;
  overflow-y: auto;
`
