import { useReadonlyMode } from '@gmini/common'
import { useStore, useStoreMap } from 'effector-react'

import { useState, useEffect, useCallback, useMemo } from 'react'
import { isNotEmpty, useWindowEventListener } from '@gmini/utils'

import * as ismApi from '@gmini/ism-api-sdk'

import { inspectionService } from '../../../../services/inspectionService'

import {
  buildUrlWithParams,
  getIssuesManagerLink,
  popupCenter,
} from '../../../../helpers'

import { envLinks } from '../../../../config'

import {
  DEVELOPMENT_MODE,
  CHECKLISTS_ISSUES_MANAGER_LOCAL_ORIGIN,
} from '../../../constants'

import { IssueSelect } from './IssueSelect'

import {
  Container,
  IconWrapper,
  WarningOutlined,
  FooterText,
  CreateButton,
  Separator,
  Inner,
  DescriptionText,
} from './CreateIssue.styled'

import issueNotFound from './issueNotFound.svg'
import {
  createLinkGTechIssue,
  fetchListGTechIssue,
  fetchListGTechIssuePending$,
} from './gTechIssue.action'

import { issuesList$, ZERO_SEARCH } from './gTechIssue.store'

export type CreateIssueProps = {
  groupId: number
  widthFormulaResizableCol?: number
  projectUrn: string
}

const limit = 20

export const CreateIssue = ({
  groupId,
  widthFormulaResizableCol,
  projectUrn,
}: CreateIssueProps) => {
  const [inputValueGTechSelect, setInputValueGTechSelect] = useState('')
  const [offsetList, setOffsetList] = useState(0)

  const { readonlyMode } = useReadonlyMode()

  const adaptiveMode = Number(widthFormulaResizableCol) < 386
  const inspection = useStore(inspectionService.inspection.currentInspection$)!
  const fetchListGTechIssuePending = useStore(fetchListGTechIssuePending$)
  const issuesManagerLink = useMemo(() => getIssuesManagerLink(envLinks), [])

  const { availableGTechIssues, total } = useStoreMap({
    store: issuesList$,
    keys: [inputValueGTechSelect],
    fn: ({ byId$, ids$, totalIssues$ }, [val]) => {
      const search = val || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          availableGTechIssues: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalIssues$,
        }
      }
      return { availableGTechIssues: [], total: null }
    },
  })

  useEffect(() => {
    fetchListGTechIssue({
      limit,
      offset: offsetList,
      filter: inputValueGTechSelect,
      projectUrn,
    })
  }, [inputValueGTechSelect, offsetList, projectUrn])

  useEffect(() => {
    if (offsetList === 0) {
      fetchListGTechIssue({
        limit,
        offset: offsetList,
        filter: inputValueGTechSelect,
        projectUrn,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetList])

  const onCreateGTechIssue = () => {
    try {
      if (!projectUrn) {
        throw new Error('Не выбран проект')
      }
      const targetOrigin = DEVELOPMENT_MODE
        ? CHECKLISTS_ISSUES_MANAGER_LOCAL_ORIGIN
        : issuesManagerLink

      if (!targetOrigin) {
        return
      }

      popupCenter(
        buildUrlWithParams(`${targetOrigin}/issue/create`, {
          projectUrn,
        }),
        'createIssuePopup',
        600,
        700,
      )
    } catch (error) {
      console.error('error', error)
    }
  }

  const onScrollList = useCallback(
    event => {
      if (fetchListGTechIssuePending) {
        return
      }
      const isScrolledToEnd =
        Number(event.target?.scrollTop) + Number(event.target?.clientHeight) >=
        Number(event.target?.scrollHeight) - 20
      if (isScrolledToEnd && availableGTechIssues?.length < Number(total)) {
        setOffsetList(prevValue => prevValue + limit)
      }
    },
    [availableGTechIssues?.length, total, fetchListGTechIssuePending],
  )

  const onMessage = useCallback(
    event => {
      if (!DEVELOPMENT_MODE && event.origin !== issuesManagerLink) {
        return
      }

      if (event.data && event.data.type === 'IssuePopulated') {
        const issue = event.data as ismApi.GTechIssue

        createLinkGTechIssue({
          issueId: issue.id,
          groupId,
          inspectionId: inspection.id,
          inspectionVersion: inspection.version,
        })
      }
    },
    [groupId, inspection.id, inspection.version, issuesManagerLink],
  )

  useWindowEventListener('message', onMessage)

  return (
    <Container>
      <div style={{ flex: '3' }} />
      <Inner>
        <IconWrapper>
          <img src={issueNotFound} alt='issues' />
        </IconWrapper>
        <DescriptionText>Замечание не прикреплено.</DescriptionText>
        <DescriptionText>Выберите существующее замечание.</DescriptionText>
        <IssueSelect
          options={availableGTechIssues}
          adaptiveMode={adaptiveMode}
          setInputValue={setInputValueGTechSelect}
          onLinkIssue={value => {
            if (value && typeof value.id === 'number') {
              createLinkGTechIssue({
                groupId,
                inspectionId: inspection.id,
                inspectionVersion: inspection.version,
                issueId: value.id,
              })
            }
          }}
          onScrollList={onScrollList}
        />

        <Separator>или</Separator>

        <CreateButton
          disabled={readonlyMode.enabled}
          adaptiveMode={adaptiveMode}
          onClick={onCreateGTechIssue}
        >
          Создайте новое Замечание
        </CreateButton>
        <FooterText>
          <WarningOutlined />
          Данное замечание будет сохранено в G-mini tec
        </FooterText>
      </Inner>
      <div style={{ flex: '5' }} />
    </Container>
  )
}
