import { Group } from '@gmini/ui-kit/lib/SelectByGroups/SelectByGroups.types'
import { combine, Store } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import * as api from '@gmini/chm-api-sdk'

import { Role } from '../../components/InspectionEditor/model/role.store'

import { AssigneeListItem } from './createAssigneeGroupListService.types'

type AssigneeGroupList = Group<AssigneeListItem> & {
  source: api.AssigneeSource
}

export function createAssigneeGroupListService({
  roleList$,
  userList$,
}: {
  userList$: Store<smApi.Auth.UserData[]>
  roleList$: Store<Role[]>
}) {
  const mapUserToAssigneeOption = (
    user: smApi.Auth.UserData,
  ): AssigneeListItem => {
    const assigneeUser = {
      id: user.id,
      label: user.name,
      source: api.AssigneeSource.USER,
    }

    return assigneeUser
  }

  const mapRoleToAssigneeOption = (role: Role): AssigneeListItem => {
    const assigneeRole = {
      id: role.id,
      label: role.title,
      source: api.AssigneeSource.ROLE,
    }

    return assigneeRole
  }

  const assigneeUserList$ = userList$.map(userList =>
    userList.map(mapUserToAssigneeOption),
  )
  const assigneeRoleList$ = roleList$.map(roleList =>
    roleList.map(mapRoleToAssigneeOption),
  )

  const assigneeGroupList$ = combine(
    { userList: assigneeUserList$, roleList: assigneeRoleList$ },
    ({ roleList, userList }) => {
      const userGroup: AssigneeGroupList = {
        label: 'Пользователи',
        source: api.AssigneeSource.USER,
        options: userList,
      }

      const roleGroup: AssigneeGroupList = {
        label: 'Роли',
        source: api.AssigneeSource.ROLE,
        options: roleList,
      }

      return [userGroup, roleGroup]
    },
  )

  return {
    mapUserToAssigneeOption,
    mapRoleToAssigneeOption,
    assigneeUserList$,
    assigneeRoleList$,
    assigneeGroupList$,
  }
}
