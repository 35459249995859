import { combine } from 'effector'

import { fetchUserListPending$ } from '../model/user.store'

import { fetchRoleListPending$ } from '../model/role.store'

import {
  createChecklistLinkPending$,
  removeChecklistLinkPending$,
} from './Checklists/checklist.store'
import { fetchChecklistItem } from './Checklists/renderChecklist.store'

import {
  changeStatusPending$,
  createLinkGTechIssuePending$,
  fetchMostRecentGTechIssuePending$,
  removeLinkGTechIssuePending$,
  updateGTechIssuePending$,
} from './Issues/gTechIssue.action'
import {
  deleteFilePending$,
  downloadFilePending$,
  fetchContentFilesPending$,
  addFilePending$,
  startUploadFilePending$,
} from './Issues/file.store'
import { fetchStatusListPending$ } from './Checklists/instanceStatus.store'

export const editorPending$ = combine(
  [
    fetchChecklistItem.pending$,
    createChecklistLinkPending$,
    removeChecklistLinkPending$,
    updateGTechIssuePending$,
    fetchMostRecentGTechIssuePending$,
    removeLinkGTechIssuePending$,
    createLinkGTechIssuePending$,
    deleteFilePending$,
    downloadFilePending$,
    fetchContentFilesPending$,
    addFilePending$,
    startUploadFilePending$,
    changeStatusPending$,
    fetchStatusListPending$,
    fetchUserListPending$,
    fetchRoleListPending$,
  ],
  pendings => pendings.some(Boolean),
)
