import { IconButton, Popup } from '@gmini/ui-kit'
import React, { ReactNode } from 'react'

import {
  Wrapper,
  RoundedCross,
  Header,
  Title,
  ErrorsWrapper,
  ErrorMessage,
} from './FileValidationErrorsPopup.styled'

type FileValidationErrorsPopupProps = {
  errors: (string | ReactNode)[] | null
  onClose: () => void
  open: boolean
}

export const FileValidationErrorsPopup = ({
  errors,
  onClose,
  open,
}: FileValidationErrorsPopupProps) => (
  <Popup width='600px' onClose={onClose} open={open}>
    <Wrapper>
      <Header>
        <Title>
          {Number(errors?.length) > 1
            ? 'Некоторые файлы не были прикреплены'
            : 'Файл не был прикреплен'}
        </Title>
        <IconButton type='square'>
          <RoundedCross onClick={onClose} />
        </IconButton>
      </Header>
      <ErrorsWrapper>
        {errors?.map((error, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <ErrorMessage key={idx}>{error}</ErrorMessage>
        ))}
      </ErrorsWrapper>
    </Wrapper>
  </Popup>
)
