import { useStore, useStoreMap } from 'effector-react'
import { MouseEvent, useState, useEffect } from 'react'

import { Button, Tooltip, Triangle } from '@gmini/ui-kit'

import { useReadonlyMode } from '@gmini/common'

import * as ismApi from '@gmini/ism-api-sdk'

import {
  DeletedTitle,
  Footer,
  CrossedChain,
  EmptyTitle,
} from '../Checklists/RenderChecklist.styled'

import { resetChecklistTreeStatuses } from '../../model/checklist-status'

import { InspectionIssue } from '../../../../api'

import { IssueOfficialReplyForm } from '../IssueOfficialReplyForm'

import { RenderIssue } from './RenderIssue'

import { IssueById$ } from './renderIssue.store'

import {
  PopoverStatus,
  StatusMenuItem,
  StatusMenu,
  ChangeStatusIssueRow,
  StatusIndicator,
} from './RenderIssue.styled'
import {
  fetchMostRecentGTechIssue,
  changeStatus,
  fetchMostRecentGTechIssuePending$,
  changeStatusPending$,
} from './gTechIssue.action'
import {
  deleteFilePending$,
  fileList$,
  startUploadFilePending$,
} from './file.store'
import { issueStatusList$, PreparedIssueStatus } from './gTechIssue.store'

export type RenderIssueContainerProps = {
  issueId: number
  widthFormulaResizableCol?: number
  setAnchorElPopover?: (
    currentTarget: MouseEvent<HTMLButtonElement>['currentTarget'],
  ) => void
  type: InspectionIssue['type']
  projectUrn: string
}

export const RenderIssueContainer = ({
  issueId,
  widthFormulaResizableCol,
  setAnchorElPopover,
  type,
  projectUrn,
}: RenderIssueContainerProps) => {
  const fileList = useStore(fileList$)
  const issueData = useStoreMap({
    store: IssueById$,
    keys: [issueId],
    fn: (result, [id]) => result[issueId] || null,
  })
  const [isOfficialReplyFormOpen, setIsOfficialReplyFormOpen] = useState(false)
  const startUploadFilePending = useStore(startUploadFilePending$)
  const deleteFilePending = useStore(deleteFilePending$)
  const issueStatusList = useStore(issueStatusList$)
  const fetchMostRecentGTechIssuePending = useStore(
    fetchMostRecentGTechIssuePending$,
  )
  const changeStatusPending = useStore(changeStatusPending$)

  const { readonlyMode } = useReadonlyMode()

  const [
    anchorStatusMenuPopover,
    setAnchorStatusMenuPopover,
  ] = useState<HTMLDivElement | null>(null)

  const openStatusMenuPopover = Boolean(anchorStatusMenuPopover)

  const handleClose = () => {
    setAnchorStatusMenuPopover(null)
  }

  useEffect(() => {
    fetchMostRecentGTechIssue({ id: issueId })
  }, [issueId, type])

  useEffect(() => {
    const refreshSubscription = resetChecklistTreeStatuses.watch(() => {
      fetchMostRecentGTechIssue({ id: issueId })
    })

    return () => {
      refreshSubscription.unsubscribe()
    }
  }, [issueId, type])

  const adaptiveMode = Number(widthFormulaResizableCol) > 385

  const removeButtonJsx = (
    <Tooltip title={adaptiveMode ? '' : 'Отвязать замечание'}>
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setAnchorElPopover?.(event.currentTarget)
        }}
        color='secondary'
        disabled={readonlyMode.enabled}
        leftIcon={<CrossedChain />}
        size='regular'
      >
        {adaptiveMode && 'Отвязать'}
      </Button>
    </Tooltip>
  )

  if (fetchMostRecentGTechIssuePending && !issueData) {
    return <EmptyTitle mt='20px'>Загрузка...</EmptyTitle>
  }

  if (!issueData) {
    return (
      <>
        <EmptyTitle mt='20px'>Отсутствуют данные о замечании</EmptyTitle>
        <Footer>{removeButtonJsx}</Footer>
      </>
    )
  }

  if (issueData?.deleted) {
    return (
      <>
        <DeletedTitle>Замечание удалено</DeletedTitle>
        <Footer>{removeButtonJsx}</Footer>
      </>
    )
  }

  const onChangeStatusIssue = (item: PreparedIssueStatus) => {
    const nextStatus = item.status

    const { version, id } = issueData

    if (!version) {
      return
    }

    if (nextStatus === ismApi.IssueStatus.Enum.ON_REVIEW) {
      setIsOfficialReplyFormOpen(true)
    } else {
      changeStatus({
        issueId: id,
        issueVersion: version,
        status: nextStatus,
      })
    }

    setAnchorStatusMenuPopover(null)
  }

  let renderStatus = null

  const onSubmitOfficialReplyForm = async ({
    description,
  }: {
    description: string
  }) => {
    const { version, id } = issueData

    if (!version) {
      return
    }

    await changeStatus({
      issueId: id,
      issueVersion: version,
      status: ismApi.IssueStatus.Enum.ON_REVIEW,
      description,
    })
  }

  if (type === 'FieldInspectionIssue') {
    const allowStatusList = issueStatusList.filter(item => {
      const { allowedActions, status } = issueData

      if (
        status === ismApi.IssueStatus.Enum.ON_REVIEW &&
        item.status === ismApi.IssueStatus.Enum.IN_PROCESS
      ) {
        //TODO поставил костыль, потому что есть два пути для перевода замечания в статус IN_PROCESS из статуса ON_REVIEW
        // через кнопку "Удалить официальный ответ" и кнопку "Отклонить". Соответственно, если у замечания совпадает овнер и ответственный, то невозможно понять какое действие нужно делать через селект
        return false
      }

      return allowedActions[item.status]
    })

    // eslint-disable-next-line react/display-name
    renderStatus = (preparedIssueStatus: PreparedIssueStatus) => (
      <>
        <PopoverStatus
          open={openStatusMenuPopover && allowStatusList.length > 0}
          anchorEl={anchorStatusMenuPopover}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <StatusMenu>
            {allowStatusList.map(item => (
              <StatusMenuItem
                key={item.status}
                onClick={() => onChangeStatusIssue(item)}
                selected={issueData.status === item.status}
              >
                <StatusIndicator color={item.color} /> {item.name}
              </StatusMenuItem>
            ))}
          </StatusMenu>
        </PopoverStatus>

        {preparedIssueStatus && (
          <ChangeStatusIssueRow
            onClick={event => setAnchorStatusMenuPopover(event.currentTarget)}
            disabled={
              startUploadFilePending || deleteFilePending || changeStatusPending
            }
            clickable={allowStatusList.length > 0}
          >
            <StatusIndicator color={preparedIssueStatus.color} />
            {preparedIssueStatus.name}{' '}
            {allowStatusList.length > 0 && (
              <Triangle width='12px' color='#353B60' />
            )}
          </ChangeStatusIssueRow>
        )}
        <IssueOfficialReplyForm
          open={isOfficialReplyFormOpen}
          onClose={() => setIsOfficialReplyFormOpen(false)}
          onSubmit={onSubmitOfficialReplyForm}
        />
      </>
    )
  }

  return (
    <RenderIssue
      issueData={issueData}
      button={removeButtonJsx}
      renderStatus={renderStatus}
      type={type}
      fileList={fileList}
      projectUrn={projectUrn}
    />
  )
}
