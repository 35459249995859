import { createStore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'
import { useStoreMap } from 'effector-react'

type DynamicGroupConditionsStore = {
  [classifierId: number]:
    | smApi.UserClassifierTree.DynamicGroupItem.DynamicGroupConditionItem[]
    | undefined
}

export const dynamicGroupConditions$ = createStore<DynamicGroupConditionsStore>(
  [],
)
  .on(
    smApi.UserClassifierTree.DynamicGroupItem.getList.done,
    (state, { params, result: { dynamicGroupList } }) => ({
      ...state,
      [params.classifierId]: dynamicGroupList,
    }),
  )
  .on(
    smApi.DynamicGroup.remove.done,
    (state, { params, result: removedDynamicGroupCondition }) => {
      const currItems = state[params.classifierId] || []

      const currItemIdx = currItems.findIndex(
        ({ sourceGroupId }) =>
          sourceGroupId === removedDynamicGroupCondition.sourceGroupId,
      )

      if (currItemIdx !== -1) {
        const nextItems = [...currItems]
        nextItems.splice(currItemIdx, 1)
        return { ...state, [params.classifierId]: nextItems }
      }

      return state
    },
  )

const emptyArr: any[] = []

export function useClassifierDynamicConditions({
  classifierId,
}: {
  classifierId: number
}) {
  return useStoreMap({
    store: dynamicGroupConditions$,
    keys: [classifierId],
    fn: (store, [clsId]) =>
      store[clsId] || (emptyArr as NonNullable<typeof store[typeof clsId]>),
  })
}

smApi.UserClassifier.getMostRecent.done.watch(cls => {
  cls.result.sourceClassifiers.forEach(dep => {
    if (dep.type === 'AnonymousClassifier') {
      smApi.UserClassifierTree.DynamicGroupItem.getList.defaultContext.submit({
        classifierId: dep.id,
        classifierVersion: dep.version,
      })
    }
  })
})

smApi.UserClassifierTree.DynamicGroupItem.getList.done.watch(
  ({ params, result: { dynamicGroupList } }) => {
    smApi.UserClassifier.fetchDynamicGroupFlatItems.defaultContext.submit(
      params,
    )
  },
)

smApi.UserClassifier.addDependency.done.watch(({ params, result }) => {
  const cls = result.sourceClassifiers.find(
    cls => params.sourceClassifierId === cls.id,
  )
  if (cls && cls.type === 'AnonymousClassifier') {
    smApi.UserClassifierTree.DynamicGroupItem.getList.defaultContext.submit({
      classifierId: cls.id,
      classifierVersion: cls.version,
    })
  }
})
