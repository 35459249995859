import styled from 'styled-components'
import { Button, WarningOutlined as WarningOutlinedInit } from '@gmini/ui-kit'

import { Popover as PopoverMiu } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 100px);
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  width: max-content;
  margin: 0 auto;
  color: #353b60;
  max-width: 338px;
`

export const IconWrapper = styled.div`
  margin-bottom: 12px;
`

export const WarningOutlined = styled(WarningOutlinedInit)`
  margin-right: 4px;

  path {
    fill: rgba(53, 59, 96, 0.25);
  }
`

export const FooterText = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  font-family: Open Sans, sans-serif;
  color: rgba(53, 59, 96, 0.6);
`

export const DescriptionText = styled.div`
  word-break: break-word;
  text-align: center;
`

type CreateButtonProps = {
  disabled?: boolean
  adaptiveMode?: boolean
}

export const CreateButton = styled(Button)`
  && {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ebebef;
    font-family: Roboto, sans-serif;
    background: rgba(53, 59, 96, 1);
    transition: all 0.2s ease-out;
    margin-bottom: 8px;
    cursor: ${(props: CreateButtonProps) =>
      props.disabled ? 'default' : 'pointer'};
    ${(props: CreateButtonProps) =>
      props.adaptiveMode && 'padding: 8px 0; width: 100%;'}
    ${(props: CreateButtonProps) =>
      props.disabled &&
      'pointer-events: none; background: rgba(53, 59, 96, 0.05); color: rgba(53, 59, 96, 0.3);'}
  height: 36px;
    gap: 8px;

    &:hover {
      background: rgba(0, 0, 61, 1);
    }

    &:active {
      ${(props: CreateButtonProps) =>
        !props.disabled && 'background: rgba(53, 59, 96, 1);'};
    }
  }
`

export const Separator = styled.div`
  margin: 20px 0 23px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 340px;
  width: 100%;
  font-family: Open Sans, sans-serif;
  color: #353b60;
  overflow: hidden;

  &:after,
  &:before {
    min-width: 100%;
    content: '';
    display: block;
    height: 1px;
    background: #e5e7f1;
  }

  &:after {
    margin-left: 8px;
  }

  &:before {
    margin-right: 8px;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px 0;
`

export const SelectLabel = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000335;
`

export const SelectInner = styled.div`
  display: flex;
  gap: 0 12px;
`

export const Popover = styled(PopoverMiu)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 4px;
`

export const MenuItem = styled.div`
  padding: 6px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;

  &:hover {
    background: #f4f4f8;
  }
`

export const MenuItemsContainer = styled.div`
  padding: 7px 0;
`
