import React from 'react'

import { useStore } from 'effector-react'

import { Store, Event, combine, createStore } from 'effector'

import { equals } from 'ramda'

import { useSnackbar } from 'notistack'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'
import {
  BimRef,
  isBimNode,
  isReferenceNode,
  ReferenceNode,
  Node,
  UserClassifierNode,
  isAssemblyClassifierNode,
  AssemblyClassifierNode,
} from '@gmini/common/lib/classifier-service/Node'

import {
  DependencyTree,
  SearchModel,
  FlatNode,
  isApiFlatNode,
  ApiFlatNode,
} from '@gmini/common'

import {
  TreeLoader,
  operationsPending$,
} from '@gmini/common/lib/classifier-editor/TreeLoader'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/DependencyTree/NodeLayout'

import { isNotEmpty } from '@gmini/utils'

import { DependencyTreeModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/treeModel'

import { buildIdVersionKey } from '@gmini/common/lib/classifier-editor/DependencyTree/model/buildIdVersionKey'

import {
  getInclusionKey,
  InclusionState,
  translateInclusionMessage,
} from '@gmini/common/lib/classifier-editor/DependencyTree/Inclusion'

import { ExpandModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/expandModel'

import { CheckedModel } from '@gmini/common/lib/classifier-editor/CheckedModel'
import { pendingMapClassifier$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import {
  apiToNodeTypeMap,
  isGroupType,
  nodeToApiTypeMap,
} from '@gmini/common/lib/classifier-service/adapters'

import {
  getParentsByPath,
  getClassifierInfo,
  populateReference,
  getRefFromPathKey,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import * as smApi from '@gmini/sm-api-sdk'

import { BimNodeIcon } from '@gmini/common/lib/classifier-editor/ClassifierTree/BimNodeIcon'

import { Dot, WarningFilled } from '@gmini/ui-kit'

import { TreeNode } from '@gmini/common/lib/classifier-editor/DependencyTree/types'

import {
  getViewerId,
  validateModelTypes,
} from '@gmini/common/lib/classifier-editor/Common'

import * as api from '../../../api'

import { classifierService } from '../../../services/classifierService'

import {
  buildChecklistStatusKey,
  createCheckListStatusModel,
} from '../model/checklist-status'
import { dependencyTreeModel } from '../model/dependencyTreeModel'
import { dependencyExpandModel } from '../model/dependencyExpandModel'
import {
  fetchDependencyItemsStatus,
  resetRequestedStatuses,
} from '../model/fetchItemsStatus'
import { StatusIconWrap } from '../common/statusIconWrap.styled'
import { dynamicGroupConditions$ } from '../model/dynamic-conditions.store'

const inclusionStatusWithTranscript = {
  PARENT_INCLUDED: `Родительский элемент добавлен в задачу`,
  SELF_INCLUDED: `Элемент добавлен в задачу`,
  CHILDREN_INCLUDED: `Один из дочерних элементов добавлен в задачу`,
}

type DependencyTreeWrapProps = {
  searchModel: SearchModel
  fetchAllInclusion: Event<void>
  loadOnceNodeInclusion: (params: {
    node: Node
    sourceClassifierId: number
    sourceClassifierVersion: number
    path: string[]
  }) => void
  dependencyExpandModel: ExpandModel
  filteredFlatTree$: Store<FlatNode[]>
  currentUserClassifier$: Store<UserClassifierNode | null>
  nodes$: Store<Nodes>
  dependencyTreeModel: DependencyTreeModel
  dependencyCheckedModel: CheckedModel
  currentInspection: api.Inspection
  checkListTheme: boolean
  sourceClassifiersLoaded$: Store<{
    readonly [key: string]: boolean | undefined
  }>
  searchSourceData$: Store<{
    id: number
    version: number
  } | null>
  selectForgeRefs: (value: Record<string, string[]>) => void
  inclusionStore$: Store<InclusionState>
}
export const checklistStatus = createCheckListStatusModel({
  apiCall: api.ChecklistStatus.fetchDependencyTreeStatuses,
  onBuildKey: ({ item, params }) =>
    buildChecklistStatusKey({
      id: item.itemId,
      type: apiToNodeTypeMap[item.itemType],
      parentId: params.sourceClassifierId,
    }),
  onReset: resetRequestedStatuses,
})

const issueStatus = createCheckListStatusModel({
  apiCall: api.IssueStatus.fetchDependencyItemsStatus,
  onBuildKey: ({ item, params }) =>
    buildChecklistStatusKey({
      id: item.itemId,
      type: apiToNodeTypeMap[item.itemType],
      parentId: params.sourceClassifierId,
    }),
  onReset: resetRequestedStatuses,
})

const requestedStatusItems$ = createStore<{
  [key: string]: true | undefined
}>({})
  .on(fetchDependencyItemsStatus, (state, { items, sourceClassifierId }) => {
    const next = { ...state }
    items.forEach(item => {
      next[
        buildChecklistStatusKey({
          id: item.itemId,
          type: item.itemType,
          parentId: sourceClassifierId,
        })
      ] = true
    })
    return next
  })
  .reset(resetRequestedStatuses)

const expandedTreeItems$ = combine(
  {
    tree: dependencyTreeModel.flatTree$,
    expanded: dependencyExpandModel.expanded$,
  },
  ({ expanded, tree }) =>
    tree.filter(isApiFlatNode).filter(item => expanded[item.path.join(':')]),
)

const treeRootItems$ = dependencyTreeModel.flatTree$.map(tree =>
  tree.filter(({ path }) => path.length === 1).filter(isApiFlatNode),
)

function getRequestStatusItems({
  flatTree,
  nodes,
  requested,
}: {
  flatTree: ApiFlatNode[]
  nodes: Nodes
  requested: { [key: string]: true | undefined }
}): (api.InspectionItem.DependencyRequestItem & {
  sourceClassifierId: smApi.Id
  sourceClassifierVersion: smApi.Version
})[] {
  return flatTree
    .map(item => {
      const node = getNode(nodes, item.ref)
      if (!node) {
        return null
      }

      const trueNode = isReferenceNode(node)
        ? getNode(nodes, node.element)
        : node
      if (!trueNode) {
        return null
      }

      const itemType = isGroupType(trueNode.type)
        ? 'UserClassifierGroup'
        : (nodeToApiTypeMap[trueNode.type] as any)

      const { sourceClassifierId, sourceClassifierVersion } = getClassifierInfo(
        isReferenceNode(node)
          ? populateReference(classifierService.nodes$.getState(), node)
          : node,
        () => {
          const parents = getParentsByPath(
            classifierService.nodes$.getState(),
            item.path,
          )
          const assembly = parents.find(isAssemblyClassifierNode)

          return (
            assembly?.assemblyModelRef || parents.find(isReferenceNode) || null
          )
        },
      )

      const requestItem = {
        itemType,
        itemId: trueNode.id,
        sourceClassifierId,
        sourceClassifierVersion,
      }
      if (
        itemType === 'UserClassifier' ||
        itemType === 'AnonymousClassifier' ||
        itemType === 'UserClassifierGroup'
      ) {
        return requestItem
      } else if (isBimNode(trueNode)) {
        return {
          ...requestItem,
          modelVersion:
            trueNode.type === 'BimModelNode'
              ? trueNode.version
              : trueNode.modelVersion,
        }
      }

      return null
    })
    .filter(isNotEmpty)
    .filter(
      item =>
        !requested[
          buildChecklistStatusKey({
            id: item.itemId,
            type: item.itemType,
            parentId: item.sourceClassifierId,
          })
        ],
    )
}

export const DependencyTreeWrap = ({
  searchModel,
  dependencyExpandModel,
  fetchAllInclusion,
  filteredFlatTree$,
  loadOnceNodeInclusion,
  dependencyTreeModel,
  currentUserClassifier$,
  nodes$,
  dependencyCheckedModel,
  currentInspection,
  checkListTheme,
  sourceClassifiersLoaded$,
  searchSourceData$,
  selectForgeRefs,
  inclusionStore$,
}: DependencyTreeWrapProps) => {
  const { searchMatched$ } = searchModel
  const currentUserClassifier = useStore(currentUserClassifier$)
  const inclusionStore = useStore(inclusionStore$)
  const includedCls =
    currentUserClassifier && inclusionStore[currentUserClassifier.id]
  const nodes = useStore(nodes$)

  const pendingMap = useStore(pendingMapClassifier$)
  const sourceClassifiersLoaded = useStore(sourceClassifiersLoaded$)
  const expandedTreeItems = useStore(expandedTreeItems$)
  const treeRootItems = useStore(treeRootItems$)
  const requestedStatusItems = useStore(requestedStatusItems$)
  const operationsPending = useStore(operationsPending$)

  const loadedDeps = currentUserClassifier?.sourceClassifiers
    .map(r => getNode(nodes$.getState(), r))
    .filter(isNotEmpty)
    .every(
      ({ id, version }) =>
        sourceClassifiersLoaded[buildIdVersionKey({ id, version })],
    )

  React.useEffect(() => {
    if (currentUserClassifier?.id && currentUserClassifier.version) {
      smApi.getSourceClassifiersVersions.submit({
        id: currentUserClassifier.id,
        version: currentUserClassifier.version,
      })
    }
  }, [currentUserClassifier?.id, currentUserClassifier?.version])
  const checkListStatusMap = useStore(checklistStatus.statusMap$)
  const issueStatusMap = useStore(issueStatus.statusMap$)

  React.useEffect(() => {
    if (checkListTheme) {
      const rootItems = getRequestStatusItems({
        flatTree: treeRootItems,
        nodes,
        requested: requestedStatusItems,
      })

      const expandedItems = getRequestStatusItems({
        flatTree: expandedTreeItems,
        nodes,
        requested: requestedStatusItems,
      }).filter(item => rootItems.every(it => !equals(item, it)))

      const requests = [...rootItems, ...expandedItems].reduce(
        (
          acc,
          {
            sourceClassifierVersion,
            sourceClassifierId,
            itemId,
            itemType,
            modelVersion,
          },
        ) => {
          const addedRequest = acc.find(
            item => item.sourceClassifierId === sourceClassifierId,
          )
          const item = modelVersion
            ? { itemId, itemType, modelVersion }
            : { itemId, itemType }

          if (addedRequest) {
            return [
              ...acc.filter(
                item => item.sourceClassifierId !== sourceClassifierId,
              ),
              {
                ...addedRequest,
                items: [...addedRequest.items, item],
              },
            ]
          }

          return [
            ...acc,
            {
              fieldInspectionId: currentInspection.id,
              fieldInspectionVersion: currentInspection.version,
              sourceClassifierId,
              sourceClassifierVersion,
              items: [item],
            },
          ]
        },
        [] as api.InspectionItem.FetchDependencyItemsParams[],
      )

      if (requests.length) {
        requests.forEach(fetchDependencyItemsStatus)
      }
    }
  }, [
    checkListTheme,
    currentInspection.id,
    currentInspection.version,
    expandedTreeItems,
    nodes,
    requestedStatusItems,
    treeRootItems,
  ])

  React.useEffect(() => {
    if (loadedDeps) {
      fetchAllInclusion()
    }
  }, [fetchAllInclusion, loadedDeps])

  const findAnywhere = React.useCallback(
    (
      ref:
        | BimRef
        | Pick<ReferenceNode, 'id' | 'type'>
        | Pick<AssemblyClassifierNode, 'id' | 'type'>,
      path: string[],
    ) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (node?.type === 'AssemblyClassifierNode' && node.assemblyModelRef) {
        node = getNode(nodes, node.assemblyModelRef.element)
      }

      if (node && isBimNode(node)) {
        searchModel.setSearchNode({ node, path })
        if (node.viewerRefs) {
          const bimNode = node
          selectForgeRefs(
            node.viewerRefs.reduce<Record<string, string[]>>(
              (acc, viewerRef) => {
                const viewerId = getViewerId({
                  node: bimNode,
                  viewerRef,
                  nodes,
                  getNodeFunc: getNode,
                  validateModelTypes,
                })
                return {
                  ...acc,
                  [viewerId]: [viewerRef.externalId],
                }
              },
              {},
            ),
          )
        }
      }
    },
    [nodes, searchModel, selectForgeRefs],
  )

  const onPending = React.useCallback((key: string) => !!pendingMap[key], [
    pendingMap,
  ])

  const { enqueueSnackbar } = useSnackbar()

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const removeRef = React.useCallback(
    (params: {
      readonly id: number
      readonly version: number
      readonly sourceClassifierId: number
    }) => {
      smApi.UserClassifier.removeDependency.defaultContext.submit(params)
    },
    [],
  )

  const onLoadInclusion = React.useCallback(
    (params: {
      node: Node
      sourceClassifierId: number
      sourceClassifierVersion: number
      path: string[]
    }) => {
      loadOnceNodeInclusion(params)
    },
    [loadOnceNodeInclusion],
  )

  const getInclusion = React.useCallback(
    (key: string, clsId: number) => {
      const _node = getNode(nodes, getRefFromPathKey(key))

      if (includedCls && _node) {
        const clsMap = includedCls[clsId]
        const included = clsMap?.[getInclusionKey(_node)]

        return included || []
      }
      return null
    },
    [includedCls, nodes],
  )

  const dynamicGroupsConditions = useStore(dynamicGroupConditions$)

  if (!currentUserClassifier) {
    return null
  }

  const isNotAppliedDynamicGrouping = (node: TreeNode) =>
    node.type === 'AnonymousClassifierNode' &&
    dynamicGroupsConditions[node.id]?.length &&
    !Object.values(nodes.DynamicBaseGroupNode).some(
      ({ parentClassifierId }) => parentClassifierId === node.id,
    )

  return (
    <>
      <TreeLoader />
      <DependencyTree
        dynamicGroupsConditions={dynamicGroupsConditions}
        notify={notify}
        isIncluded={getInclusion}
        nodes$={nodes$}
        currentEntity={currentUserClassifier}
        treeModel={{ ...dependencyTreeModel, flatTree$: filteredFlatTree$ }}
        checkedModel={dependencyCheckedModel}
        expandModel={dependencyExpandModel}
        selectedFromOtherTreeCount={0}
        onFindAnywhere={findAnywhere}
        onDelete={removeRef}
        searchMatched$={searchMatched$}
        onPending={onPending}
        selectedPath={{ path: [] }}
        onLoadInclusion={onLoadInclusion}
        hideCtxMenu={operationsPending}
        searchSourceData$={searchSourceData$}
        errorNode={node =>
          isNotAppliedDynamicGrouping(node)
            ? 'Нет результатов расчета для данной версии объема'
            : null
        }
        renderNodeLayout={({ node, nodeLayoutProps, path }) => {
          const nextProps = {
            ...nodeLayoutProps,
          }

          if (isNotAppliedDynamicGrouping(node)) {
            nextProps.expandJsx = (
              <div style={{ width: '24px', height: '24px' }} />
            )
          }

          const { sourceClassifierId } = getClassifierInfo(node, () => {
            const parents = getParentsByPath(nodes$.getState(), path)
            const assembly = parents.find(isAssemblyClassifierNode)

            return (
              assembly?.assemblyModelRef ||
              parents.find(isReferenceNode) ||
              null
            )
          })

          if (includedCls) {
            const key = path[path.length - 1]

            const includedMap = path.reduce(
              (
                acc: Record<string, smApi.InclusionStatus.InclusionValue[]>,
                next,
              ) => {
                acc[next] = getInclusion(next, sourceClassifierId) || []
                return acc
              },
              {},
            )
            const parentIncluded = Object.keys(includedMap).find(
              val =>
                val !== key && !!includedMap[val].includes('SELF_INCLUDED'),
            )

            const included: smApi.InclusionStatus.InclusionValue[] = parentIncluded
              ? [...includedMap[key], 'PARENT_INCLUDED']
              : includedMap[key]

            if (included.length) {
              nextProps.textStyle = {
                ...nextProps.textStyle,
                color: 'rgba(0, 3, 53, 0.4)',
              }

              nextProps.tooltipTitle = translateInclusionMessage(
                inclusionStatusWithTranscript,
                [...new Set(included)],
              )
            }
          }

          const trueNode = node.type === 'ReferenceNode' ? node.element : node

          const checkListStatusInfo =
            checkListStatusMap[
              buildChecklistStatusKey({
                id: trueNode.id,
                type: trueNode.type,
                parentId: sourceClassifierId,
              })
            ]
          const issueStatusInfo =
            issueStatusMap[
              buildChecklistStatusKey({
                id: trueNode.id,
                type: trueNode.type,
                parentId: sourceClassifierId,
              })
            ]

          if (issueStatusInfo && checkListTheme) {
            const errIcon = issueStatusInfo.hasIssues ? (
              <WarningFilled />
            ) : (
              <Dot />
            )

            nextProps.endContent = (
              <>
                {<StatusIconWrap>{errIcon}</StatusIconWrap>}
                {nextProps.endContent}
                {nextProps.moreActionButton}
              </>
            )
          }

          if (checkListStatusInfo && checkListTheme) {
            let iconColor: string
            let textColor: string

            switch (checkListStatusInfo?.status || 'NOT_STARTED') {
              case 'COMPLETED':
                iconColor = '#0D9966'
                textColor = '#0D9966'
                break
              case 'IN_PROGRESS':
                iconColor = '#DAC374'
                textColor = '#9A7A0C'
                break
              case 'NOT_STARTED':
                iconColor = '#A2A3B7'
                textColor = '#A2A3B7'
            }

            const bimNode = node.type === 'ReferenceNode' ? node.element : node

            switch (bimNode.type) {
              case 'BimModelNode':
              case 'BimFamilyNode':
              case 'BimCategoryNode':
              case 'BimStandardSizeNode':
              case 'BimElementNode':
                nextProps.icon = (
                  <BimNodeIcon
                    type={bimNode.type}
                    color={iconColor}
                    opacity='1'
                  />
                )
            }

            nextProps.textStyle = {
              ...nextProps.textStyle,
              color: textColor,
            }
          }

          return <NodeLayout {...nextProps} />
        }}
      />
    </>
  )
}
