import React, { useCallback, useState, useMemo } from 'react'
import moment from 'moment'

import {
  LinkButtonBlue,
  GTechLogo,
  SelectByGroupsMultiple,
} from '@gmini/ui-kit'

import { useStore, useStoreMap } from 'effector-react'

import * as chmApi from '@gmini/chm-api-sdk'

import { InspectionChecklist } from '../../../../api/InspectionRepo/InspectionChecklist'

import { fetchUserListPending$ } from '../../model/user.store'

import { envLinks } from '../../../../config'

import {
  buildUrlWithParams,
  getChecklistsManagerLink,
} from '../../../../helpers'

import { fetchRoleListPending$ } from '../../model/role.store'

import { useAssignees } from '../../common/useAssignees'

import {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeUserList$,
} from '../../model/assigneeGroupList'

import { AssigneeListItem } from '../../../../services/createAssigneeGroupListService'

import {
  ChecklistData,
  updateInstance,
  updateInstancePending$,
} from './renderChecklist.store'

import {
  Title,
  Container,
  Row,
  RowLabel,
  TableData,
  TableHead,
  Table,
  RowValue,
  StatusIndicator,
  Footer,
  Link,
} from './RenderChecklist.styled'
import { instanceStatusListByStatus$ } from './instanceStatus.store'

export type RenderChecklistProps = {
  checklistData: ChecklistData
  button: React.ReactNode
  type: InspectionChecklist['type']
  checklistId: string | number
  projectUrn: string
}

export const RenderChecklist = ({
  checklistData,
  button,
  type,
  checklistId,
  projectUrn,
}: RenderChecklistProps) => {
  const checklistsManagerLink = useMemo(
    () => getChecklistsManagerLink(envLinks),
    [],
  )
  const {
    completedItemsCount,
    id,
    version,
    totalItems,
    name,
    createdAt,
    updatedAt,
    templateTypeDescription,
    sectionsCount,
    completedSectionCount,
    description,
  } = checklistData

  const status = useStoreMap({
    store: instanceStatusListByStatus$,
    keys: [checklistData.status],
    fn: (instanceStatusListByStatus, [status]) =>
      instanceStatusListByStatus?.[status] || null,
  })

  const assigneeGroupList = useStore(assigneeGroupList$)
  const getAssignees = useAssignees({ assigneeRoleList$, assigneeUserList$ })
  const assigneesInit = getAssignees(checklistData.assignees)
  const [assignees, setAssignees] = useState(assigneesInit)

  const fetchUserListPending = useStore(fetchUserListPending$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)
  const updateInstancePending = useStore(updateInstancePending$)

  const onUpdateInstance = useCallback(
    (params: Partial<chmApi.Instance.UpdateParams>) => {
      updateInstance({ id, version, ...params })
    },
    [id, version],
  )

  const onChangeAssignee = useCallback(
    (value: AssigneeListItem[]) => {
      if (
        value.length &&
        value.every(valueItem =>
          assigneesInit.find(({ id }) => id === valueItem.id),
        )
      ) {
        return
      }

      onUpdateInstance({
        assignees: value.map(({ source, id }) => ({
          assigneeId: id,
          source,
        })),
      })
    },
    [assigneesInit, onUpdateInstance],
  )

  const isGTechChecklist = type === 'FieldInspectionChecklist'

  let openChecklistLink = null

  if (isGTechChecklist && checklistsManagerLink) {
    openChecklistLink = (
      <Link
        href={buildUrlWithParams(
          `${checklistsManagerLink}/instance/edit/${checklistId}`,
          { projectUrn },
        )}
        target='_blank'
        rel='noreferrer'
      >
        <LinkButtonBlue>Открыть в G-tec Менеджер Чек-листов</LinkButtonBlue>
      </Link>
    )
  }

  return (
    <Container>
      <Title>{name}</Title>

      <Row>
        <RowLabel>Источник</RowLabel>

        <RowValue>{isGTechChecklist && <GTechLogo />}</RowValue>
      </Row>

      {status && (
        <Row>
          <RowLabel>Статус</RowLabel>

          <RowValue bold>
            <StatusIndicator color={status.color} />
            {status.name}
          </RowValue>
        </Row>
      )}

      {createdAt && (
        <Row>
          <RowLabel>Дата создания</RowLabel>
          <RowValue>{moment(createdAt).format('LL')}</RowValue>
        </Row>
      )}

      {updatedAt && (
        <Row>
          <RowLabel>Дата модификации</RowLabel>
          <RowValue>{moment(updatedAt).format('LL')}</RowValue>
        </Row>
      )}

      {isGTechChecklist && (
        <Row>
          <RowLabel>Ответственный за чек-лист</RowLabel>
          <RowValue bold>
            <SelectByGroupsMultiple
              onChange={setAssignees}
              onBlur={onChangeAssignee}
              onReset={() => {
                setAssignees([])
                onChangeAssignee([])
              }}
              value={assignees}
              placeholder='Выберите ответственного'
              groups={assigneeGroupList}
              getOptionLabel={option => option.label}
              disabled={
                fetchUserListPending ||
                fetchRoleListPending ||
                updateInstancePending
              }
              colorScheme='white'
              emptyOptionListMessage='Нет доступных совпадений'
            />
          </RowValue>
        </Row>
      )}

      {templateTypeDescription && (
        <Row>
          <RowLabel>Дисциплина</RowLabel>

          <RowValue bold>{templateTypeDescription}</RowValue>
        </Row>
      )}

      {description && (
        <Row>
          <RowLabel>Описание</RowLabel>
          <RowValue>{description}</RowValue>
        </Row>
      )}

      <Table>
        <thead>
          <tr>
            <TableHead>Секции</TableHead>
            <TableHead>Вопросы</TableHead>
            <TableHead>Подписи</TableHead>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableData>
              {completedSectionCount}/{sectionsCount}
            </TableData>
            <TableData>
              {completedItemsCount}/{totalItems}
            </TableData>
            <TableData>0/0</TableData>
          </tr>
        </tbody>
      </Table>

      <Footer>
        {openChecklistLink}
        {button}
      </Footer>
    </Container>
  )
}
