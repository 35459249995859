import { combine, createEvent, createStore } from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'

import {
  defaultIssueStatusColor,
  issueStatusColorMap,
} from '../../../constants'

import {
  fetchListGTechIssue,
  fetchListGTechIssuePending$,
  fetchMostRecentGTechIssue,
} from './gTechIssue.action'

export const addIssueToList = createEvent<ismApi.GTechIssue.Issue>()
export const resetIssueList = createEvent()

export const fetchStatusList = ismApi.IssueStatus.fetchList.createContext()
export const fetchStatusListPending$ = fetchStatusList.pending$

type ById = {
  [id: string]: ismApi.GTechIssue.Issue
}

export const issuePending$ = combine([fetchListGTechIssuePending$], pendings =>
  pendings.some(Boolean),
)

const byId$ = createStore<ById>({})
  .on(fetchListGTechIssue.doneData, (state, result) => {
    const next = { ...state }
    result.list.forEach(ch => (next[ch.id] = ch))
    return next
  })
  .on(fetchMostRecentGTechIssue.doneData, (state, result) => ({
    ...state,
    [result.id]: result,
  }))

type IdsBySearchValue = Record<string | symbol, number[] | undefined>

export const ZERO_SEARCH = 'áÝé¾±µ'

const ids$ = createStore<IdsBySearchValue>({})
  .on(fetchListGTechIssue.done, (state, { params, result }) => {
    const next = { ...state }
    const search = params.filter || ZERO_SEARCH
    next[search] = [
      ...new Set([
        ...(state[search] || []),
        ...result.list.map(({ id }) => id),
      ]),
    ]
    return next
  })
  .on(resetIssueList, state => ({}))

export const totalIssues$ = createStore<number | null>(null).on(
  fetchListGTechIssue.doneData,
  (state, result) => result.total,
)

export const issuesList$ = combine({ byId$, ids$, totalIssues$ })

export const fetchIssueTypes = ismApi.GTechIssueType.fetchList.createContext()
export const fetchIssueTypesPending$ = fetchIssueTypes.pending$

export const issueTypes$ = createStore<ismApi.GTechIssueType[]>(
  [],
).on(fetchIssueTypes.doneData, (state, result) => (result ? result : state))

export type PreparedIssueStatus = ismApi.IssueStatus.Data & { color: string }

export const issueStatusList$ = createStore<PreparedIssueStatus[]>([]).on(
  fetchStatusList.doneData,
  (state, result) =>
    result.map(item => ({
      ...item,
      color: issueStatusColorMap[item.status] || defaultIssueStatusColor,
    })),
)
