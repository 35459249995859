import { useStore } from 'effector-react'
import React, { useEffect } from 'react'

import { Button } from '@gmini/ui-kit'

import { inspectionService } from '../../../../services/inspectionService'

import { fetchUserList } from '../../model/user.store'

import { InspectionIssue } from '../../../../api'

import { fetchRoleList } from '../../model/role.store'

import { CreateIssue } from './CreateIssue'
import {
  Popover,
  WarningActions,
  WarningContainer,
  WarningDeleteText,
} from './RenderIssue.styled'

import { RenderIssueContainer } from './RenderIssueContainer'
import { removeLinkGTechIssue } from './gTechIssue.action'
import { fetchIssueTypes } from './gTechIssue.store'

export type IssueProps = {
  groupId: number
  widthFormulaResizableCol?: number
  projectUrn: string
}

export const Issues = ({
  groupId,
  widthFormulaResizableCol,
  projectUrn,
}: IssueProps) => {
  const inspection = useStore(inspectionService.inspection.currentInspection$)!

  const [
    anchorElPopover,
    setAnchorElPopover,
  ] = React.useState<HTMLButtonElement | null>(null)

  const openPopover = Boolean(anchorElPopover)

  const handleClosePopover = () => {
    setAnchorElPopover(null)
  }

  const unlinkGTechIssue = (issueId: number) => {
    removeLinkGTechIssue({
      inspectionIssueId: issueId,
      inspectionId: inspection.id,
      inspectionVersion: inspection.version,
    })
    handleClosePopover()
  }

  const issues = inspection.issues?.filter(
    ch => ch.groupId === groupId && ch.type !== 'FieldInspectionForgeIssue',
  ) as InspectionIssue[] | undefined

  useEffect(() => {
    fetchUserList.submit({ projectUrn })
    fetchIssueTypes.submit()
    fetchRoleList.submit()
  }, [projectUrn])

  if (issues && Number(issues?.length) > 0) {
    return (
      <>
        {issues.map(issue => (
          <React.Fragment key={issue.id}>
            <RenderIssueContainer
              issueId={issue.issueId}
              widthFormulaResizableCol={widthFormulaResizableCol}
              key={issue.id}
              setAnchorElPopover={setAnchorElPopover}
              type={issue.type}
              projectUrn={projectUrn}
            />
            <Popover
              open={openPopover}
              anchorEl={anchorElPopover}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <WarningContainer>
                <WarningDeleteText>
                  Вы действительно хотите отвязать замечание?
                </WarningDeleteText>
                <WarningActions>
                  <Button
                    color='warning'
                    onClick={() => unlinkGTechIssue(issue.id)}
                    size='regular'
                  >
                    Отвязать
                  </Button>
                  <Button
                    size='regular'
                    color='secondary'
                    onClick={handleClosePopover}
                  >
                    Отмена
                  </Button>
                </WarningActions>
              </WarningContainer>
            </Popover>
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <CreateIssue
      groupId={groupId}
      widthFormulaResizableCol={widthFormulaResizableCol}
      projectUrn={projectUrn}
    />
  )
}
