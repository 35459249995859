import React, { useEffect, useMemo } from 'react'
import { combine, createStore, createEvent } from 'effector'
import { useStore } from 'effector-react'

import {
  Badge,
  FormulaeEditorLayout,
  Tabs,
  Setting,
  Tooltip,
} from '@gmini/ui-kit'

import { assertNever } from '@gmini/utils'

import * as smApi from '@gmini/sm-api-sdk'

import { Box } from '@gmini/ui-kit/lib/Box'

import { useSelectedGroupNode } from '../CurrentGroup'

import { inspectionService } from '../../../services/inspectionService'

import {
  buildUrlWithParams,
  getChecklistsManagerLink,
  getIssuesManagerLink,
} from '../../../helpers'

import { envLinks } from '../../../config'

import { Checklists } from './Checklists'
import { Issues } from './Issues'
import {
  Container,
  LinearProgress,
  Link,
  ManagerButton,
} from './GroupSettings.styled'
import { editorPending$ } from './editorPending'
import { fetchTypes } from './templateTypes'
import { fetchStatusList } from './Issues/gTechIssue.store'

export enum TabIndex {
  Checklist,
  Issue,
}

const editorDisabled$ = combine(
  [
    smApi.UserClassifierGroup.createGroupReference.defaultContext.pending$,
    smApi.UserClassifierGroup.create.defaultContext.pending$,
    smApi.UserClassifierGroup.move.defaultContext.pending$,
    smApi.UserClassifierGroup.rename.defaultContext.pending$,
    smApi.UserClassifierGroup.remove.defaultContext.pending$,
    smApi.BimReference.create.defaultContext.pending$,
    smApi.BimReference.move.defaultContext.pending$,
    smApi.BimReference.remove.defaultContext.pending$,
    smApi.BimReference.createRefs.defaultContext.pending$,
    smApi.BimReference.moveRefs.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

export const tabIndex$ = createStore(0)
export const setTabIndex = createEvent<number>()
tabIndex$.on(setTabIndex, (state, result) => result)

type GroupSettingsProps = {
  widthFormulaResizableCol?: number
}

export const GroupSettings = React.memo(
  ({ widthFormulaResizableCol }: GroupSettingsProps) => {
    const checklistsManagerLink = useMemo(
      () => getChecklistsManagerLink(envLinks),
      [],
    )
    const issuesManagerLink = useMemo(() => getIssuesManagerLink(envLinks), [])

    const editorDisabled = useStore(editorDisabled$)
    const inspection = useStore(
      inspectionService.inspection.currentInspection$,
    )!
    const { projectUrn } = inspection
    const tabIndex = useStore(tabIndex$)
    const editorPending = useStore(editorPending$)

    const selectedGroup = useSelectedGroupNode()

    const countIssues = Number(
      inspection.issues?.filter(ch => ch.groupId === selectedGroup?.id).length,
    )

    const columnWidthBreakpoint = 400
    useEffect(() => {
      fetchTypes.submit()
      fetchStatusList.submit()
      fetchStatusList.submit()
    }, [])

    const handleChangeTab = (newTabIndex: number) => {
      setTabIndex(newTabIndex)
    }

    const renderContent = (currGroup: NonNullable<typeof selectedGroup>) => {
      switch (tabIndex) {
        case TabIndex.Checklist:
          return (
            <div style={{ height: '100%' }}>
              <Box pt='20px' height='100%'>
                <Checklists
                  groupId={currGroup.id}
                  widthFormulaResizableCol={widthFormulaResizableCol}
                  projectUrn={projectUrn}
                />
              </Box>
            </div>
          )

        case TabIndex.Issue:
          return (
            <div style={{ height: '100%' }}>
              <Box pt={countIssues > 0 ? 0 : '20px'} height='100%'>
                <Issues
                  groupId={currGroup.id}
                  widthFormulaResizableCol={widthFormulaResizableCol}
                  projectUrn={projectUrn}
                />
              </Box>
            </div>
          )

        default:
          return assertNever('Unexpected tabIndex', tabIndex as never)
      }
    }

    if (!selectedGroup) {
      return null
    }

    return (
      <Container>
        <LinearProgress show={editorPending} />
        {tabIndex === TabIndex.Checklist && checklistsManagerLink && (
          <Tooltip
            title={
              Number(widthFormulaResizableCol) > columnWidthBreakpoint
                ? ''
                : 'Список Чеклистов'
            }
            styleContent={{ position: 'absolute', right: '20px' }}
          >
            <Link
              href={buildUrlWithParams(checklistsManagerLink, {
                projectUrn,
              })}
              target='_blank'
            >
              <ManagerButton
                rightIcon={<Setting width='20px' height='20px' />}
                size='small'
                color='tertiary'
              >
                {Number(widthFormulaResizableCol) > columnWidthBreakpoint &&
                  'Список Чеклистов'}
              </ManagerButton>
            </Link>
          </Tooltip>
        )}
        {tabIndex === TabIndex.Issue && issuesManagerLink && (
          <Tooltip
            title={
              Number(widthFormulaResizableCol) > columnWidthBreakpoint
                ? ''
                : 'Список Замечаний'
            }
            styleContent={{ position: 'absolute', right: '20px' }}
          >
            <Link
              href={buildUrlWithParams(issuesManagerLink, {
                projectUrn,
              })}
              target='_blank'
            >
              <ManagerButton
                rightIcon={<Setting width='20px' height='20px' />}
                size='small'
                color='tertiary'
              >
                {Number(widthFormulaResizableCol) > columnWidthBreakpoint &&
                  'Список Замечаний'}
              </ManagerButton>
            </Link>
          </Tooltip>
        )}
        <Tabs
          onChangeTab={handleChangeTab}
          activeTabIndex={tabIndex}
          headerStyles={{
            fontSize: '14px',
            marginRight: '20px',
          }}
          contentStyles={{ height: '100%', paddingTop: '0' }}
          tabs={[
            {
              title: 'Чек-листы',
              content: (
                <FormulaeEditorLayout maxWidth='100%' disabled={editorDisabled}>
                  {renderContent(selectedGroup)}
                </FormulaeEditorLayout>
              ),
            },
            {
              title: 'Замечания',
              content: (
                <FormulaeEditorLayout
                  maxWidth='100%'
                  height='calc(100% - 50px)'
                  disabled={editorDisabled}
                >
                  {renderContent(selectedGroup)}
                </FormulaeEditorLayout>
              ),
              badge: countIssues > 0 && <Badge>{countIssues}</Badge>,
            },
          ]}
        />
      </Container>
    )
  },
)

GroupSettings.displayName = 'GroupSettings'
