import { IssueStatus } from '@gmini/ism-api-sdk'
import { InstanceStatus } from '@gmini/chm-api-sdk'

export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD.MM.YYYY'
export const FULL_DISPLAY_DATE_FORMAT = 'D MMM YYYY HH:mm'
export const CHECKLISTS_ISSUES_MANAGER_LOCAL_ORIGIN = 'http://127.0.0.1:3001'
export const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
export const PRODUCTION_MODE = process.env.NODE_ENV === 'production'

export const issueStatusColorMap: Record<IssueStatus.Enum, string> = {
  CLOSED: '#42AB85',
  DRAFT: '#E5E7F1',
  IN_PROCESS: '#DE900E',
  NOT_RELEVANT: '#E5E7F1',
  NOT_STARTED: '#A2A3B7',
  ON_REVIEW: '#4C5ECF',
}

export const defaultIssueStatusColor = '#42AB85'

export const instanceStatusColorMap: Record<InstanceStatus.Enum, string> = {
  IN_PROCESS: '#F5A623',
  FINISHED: '#0D9966',
  DRAFT: '#A7A9AC',
}

export const defaultInstanceStatusColor = '#A7A9AC'
