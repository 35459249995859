import { createStore, sample } from 'effector'
import { clone } from 'ramda'

import * as ismApi from '@gmini/ism-api-sdk'

export const addFile = ismApi.GTechIssue.addFile.createContext()
export const addFilePending$ = addFile.pending$
export const startUploadFile = ismApi.GTechIssue.startUploadFile.createContext()
export const startUploadFilePending$ = startUploadFile.pending$
export const deleteFile = ismApi.GTechIssue.deleteFile.createContext()
export const deleteFilePending$ = deleteFile.pending$
export const downloadFile = ismApi.GTechIssue.downloadFile.createContext()
export const getFile = ismApi.GTechIssue.downloadFile.createContext()
export const getFilePending$ = getFile.pending$
export const downloadFilePending$ = downloadFile.pending$
export const fetchContentFiles = ismApi.GTechIssue.fetchContentFiles.createContext()
export const fetchContentFilesPending$ = fetchContentFiles.pending$

export const fileById$ = createStore<Record<string, string>>({}).on(
  downloadFile.done,
  (state, { result, params }) => {
    const next = clone(state)

    if (params?.fileId) {
      next[params.fileId] = result
    }

    return next
  },
)

export const fileList$ = createStore<ismApi.GTechIssue.File[] | null>(null)
  .on(fetchContentFiles.doneData, (_state, result) => result)
  .on(deleteFile.doneData, (state, { id }) =>
    state?.filter(file => file.id !== id),
  )

type FileLinkById = Record<number, string>

fetchContentFiles.doneData.watch(fileList =>
  Promise.all(fileList.map(({ id }) => getFile({ fileId: id }))),
)

export const fileLinkById$ = createStore<FileLinkById>({})
  .on(getFile.done, (state, { params, result }) => {
    const next = clone(state)

    next[params.fileId] = result

    return next
  })
  .on(deleteFile.doneData, (state, { id }) => {
    const next = clone(state)

    delete next[id]

    return next
  })

sample({
  source: fileList$,
  clock: downloadFile.done,
  fn: (fileList, { params, result }) => ({
    link: result,
    filename: fileList?.find(({ id }) => id === params.fileId)?.name,
  }),
}).watch(({ filename, link }) => {
  //TODO Заменить на автоматическую загрузку файла с бэка после запроса
  const anchorLink = window.document.createElement('a')
  anchorLink.innerHTML = filename ? filename : ''
  anchorLink.href = link
  anchorLink.download = filename ? filename : ''
  window.document.body.appendChild(anchorLink)
  anchorLink.click()
  window.document.body.removeChild(anchorLink)
})
