import { useReadonlyMode } from '@gmini/common'
import { isNotEmpty, useWindowEventListener } from '@gmini/utils'
import { useStore, useStoreMap } from 'effector-react'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'

import * as chmApi from '@gmini/chm-api-sdk'

import {
  Container,
  IconWrapper,
  Inner,
  DescriptionText,
  Separator,
  CreateButton,
  WarningOutlined,
  FooterText,
} from '../Issues/CreateIssue.styled'

import { envLinks } from '../../../../config'

import {
  buildUrlWithParams,
  getChecklistsManagerLink,
  popupCenter,
} from '../../../../helpers'

import {
  CHECKLISTS_ISSUES_MANAGER_LOCAL_ORIGIN,
  DEVELOPMENT_MODE,
} from '../../../constants'

import {
  checklists$,
  createChecklistLink,
  createChecklistLinkPending$,
  fetchInstanceListPending$,
  fetchInstanceList,
} from './checklist.store'

import checklistNotFound from './checklistNotFound.svg'
import { ChecklistSelect } from './ChecklistSelect'

export type CreateIssueProps = {
  groupId: number
  widthFormulaResizableCol?: number
  projectUrn: string
}

const limit = 20

export const CreateChecklist = ({
  groupId,
  widthFormulaResizableCol,
  projectUrn,
}: CreateIssueProps) => {
  const { readonlyMode } = useReadonlyMode()
  const { enqueueSnackbar } = useSnackbar()
  const [offsetList, setOffsetList] = useState(0)
  const [inputValueSelect, setInputValueSelect] = useState('')
  const createChecklistLinkPending = useStore(createChecklistLinkPending$)
  const fetchInstanceListPending = useStore(fetchInstanceListPending$)

  const checklistsManagerLink = useMemo(
    () => getChecklistsManagerLink(envLinks),
    [],
  )
  const adaptiveMode = Number(widthFormulaResizableCol) < 386

  const { availableChecklists, total } = useStoreMap({
    store: checklists$,
    keys: [inputValueSelect],
    fn: ({ byId, ids, totalChecklists }, [val]) => {
      if (ids) {
        return {
          availableChecklists: ids.map(id => byId[id]).filter(isNotEmpty),
          total: totalChecklists,
        }
      }
      return { availableChecklists: [], total: null }
    },
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    fetchInstanceList({
      limit,
      offset: offsetList,
      filter: inputValueSelect,
      projectUrn,
    })
  }, [inputValueSelect, offsetList, projectUrn])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (offsetList === 0) {
      fetchInstanceList({
        limit,
        offset: offsetList,
        filter: inputValueSelect,
        projectUrn,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetList])

  const notify = useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const onScrollList = useCallback(
    event => {
      if (fetchInstanceListPending) {
        return
      }
      const isScrolledToEnd =
        Number(event.target?.scrollTop) + Number(event.target?.clientHeight) >=
        Number(event.target?.scrollHeight) - 20
      if (isScrolledToEnd && availableChecklists?.length < Number(total)) {
        setOffsetList(prevValue => prevValue + limit)
      }
    },
    [availableChecklists?.length, total, fetchInstanceListPending],
  )

  const onMessage = useCallback(
    event => {
      if (!DEVELOPMENT_MODE && event.origin !== checklistsManagerLink) {
        return
      }

      if (event.data && event.data.type === 'InstancePopulated') {
        const issue = event.data as chmApi.Instance.InstancePopulated

        createChecklistLink({ checklistId: issue.id, groupId })
      }
    },
    [checklistsManagerLink, groupId],
  )

  useWindowEventListener('message', onMessage)

  const onOpenCreateChecklistPopup = () => {
    try {
      if (!projectUrn) {
        throw new Error('Не выбран проект')
      }
      const targetOrigin = DEVELOPMENT_MODE
        ? CHECKLISTS_ISSUES_MANAGER_LOCAL_ORIGIN
        : checklistsManagerLink

      popupCenter(
        buildUrlWithParams(`${targetOrigin}/instance/create`, {
          projectUrn,
        }),
        'createChecklistPopup',
        600,
        700,
      )
    } catch (error) {
      console.error('error :>> ', error)
    }
  }

  return (
    <Container>
      <div style={{ flex: '3' }} />
      <Inner>
        <IconWrapper>
          <img src={checklistNotFound} alt='checklistNotFound' />
        </IconWrapper>
        <DescriptionText>
          Чек-лист не прикреплен. <br />
          Выберите существующий чек-лист.
        </DescriptionText>

        <ChecklistSelect
          onLinkChecklist={value => {
            if (value?.status === 'FINISHED') {
              notify('Невозможно привязать чек-лист со статусом "Завершен"')
              return
            }

            if (value && typeof value.id === 'number') {
              createChecklistLink({
                checklistId: value.id,
                groupId,
              })
            }
          }}
          adaptiveMode={adaptiveMode}
          options={availableChecklists}
          setInputValue={setInputValueSelect}
          onScrollList={onScrollList}
        />
        <Separator>или</Separator>
        <CreateButton
          disabled={readonlyMode.enabled || createChecklistLinkPending}
          adaptiveMode={adaptiveMode}
          onClick={onOpenCreateChecklistPopup}
        >
          Создайте новый чек-лист
        </CreateButton>
        <FooterText>
          <WarningOutlined />
          Данный чек-лист будет сохранен в G-mini tec
        </FooterText>
      </Inner>

      <div style={{ flex: '5' }} />
    </Container>
  )
}
