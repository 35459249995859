import styled, { css } from 'styled-components'
import { Shevron as ShevronInit } from '@gmini/ui-kit'

type CollapseButtonProps = {
  open?: boolean
}

export const CollapseButton = styled(ShevronInit)`
  transform: ${(props: CollapseButtonProps) =>
    props.open ? 'rotate(270deg)' : 'rotate(90deg)'};
  transition: all 0.2s ease-out;
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin-right: 8px;

  path {
    transition: all 0.2s ease-out;
  }
`

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #00003d;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-out;
`

type ContainerProps = {
  open?: boolean
}

export const Container = styled.div`
  padding: 32px 0 32px 0;
  border-bottom: 1px solid #e5e7f1;
  cursor: pointer;

  ${(props: ContainerProps) =>
    props.open &&
    css`
      padding: 32px 0 12px 0;
      cursor: default;
    `}

  &:hover {
    ${CollapseButton} {
      path {
        fill: #353b60;
      }
    }

    ${Title} {
      color: #353b60;
    }
  }
`

export const Content = styled.div`
  padding-top: 0;
`

type ContentWrapperProps = {
  open?: boolean
  height: number
}

export const ContentWrapper = styled.div`
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.2s ease-out;

  ${(props: ContentWrapperProps) =>
    props.open &&
    css`
      margin-top: 20px;
      max-height: ${props.height}px;
    `}
`
