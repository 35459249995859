import { createEvent, merge } from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'

import * as api from '../../../../api'

import { resetChecklistTreeStatuses } from '../../model/checklist-status'

export const fetchListGTechIssue = ismApi.GTechIssue.fetchList.createContext()
export const fetchListGTechIssuePending$ = fetchListGTechIssue.pending$

import { fetchContentFiles, addFile, startUploadFile } from './file.store'

export const attachFiles = createEvent<{ files: File[] }>()
export const fetchMostRecentGTechIssue = ismApi.GTechIssue.fetchMostRecent.createContext()
export const fetchMostRecentGTechIssuePending$ =
  fetchMostRecentGTechIssue.pending$
export const updateGTechIssue = ismApi.GTechIssue.update.createContext()
export const updateGTechIssuePending$ = updateGTechIssue.pending$
export const createLinkGTechIssue = api.InspectionIssue.createLinkIssue.createContext()
export const createLinkGTechIssuePending$ = createLinkGTechIssue.pending$
export const removeLinkGTechIssue = api.InspectionIssue.removeLinkIssue.createContext()
export const removeLinkGTechIssuePending$ = removeLinkGTechIssue.pending$
export const changeStatus = ismApi.GTechIssue.changeStatus.createContext()
export const changeStatusPending$ = changeStatus.pending$

fetchMostRecentGTechIssue.doneData.watch(({ id, version }) => {
  fetchContentFiles({ issueId: id, issueVersion: version })
})

merge([
  removeLinkGTechIssue.doneData,
  createLinkGTechIssue.doneData,
  updateGTechIssue.doneData,
]).watch(() => {
  resetChecklistTreeStatuses()
})

export const uploadFiles = async (
  { issueId, issueVersion }: { issueId: number; issueVersion: number },
  files: File[],
) => {
  const newIssue = await startUploadFile({
    issueId,
    issueVersion,
  })

  await Promise.all(
    files.map(file => {
      const formData = new FormData()
      formData.append('file', file, encodeURI(file.name))

      return addFile({
        formData,
        issueId: newIssue.id,
        issueVersion: newIssue.version,
      })
    }),
  )

  fetchContentFiles({
    issueId: newIssue.id,
    issueVersion: newIssue.version,
  })
}
