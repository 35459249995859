import { createAssigneeGroupListService } from '../../../services/createAssigneeGroupListService'

import { roleList$ } from './role.store'
import { userList$ } from './user.store'

export const {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeUserList$,
  mapRoleToAssigneeOption,
  mapUserToAssigneeOption,
} = createAssigneeGroupListService({ roleList$, userList$ })
