import { forward } from 'effector'

import { FetchService } from '@gmini/utils'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'

import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import * as smApi from '@gmini/sm-api-sdk'
import * as ismApi from '@gmini/ism-api-sdk'
import * as chmApi from '@gmini/chm-api-sdk'
import * as estApi from '@gmini/sm-api-sdk/lib/EstimationApi'
import * as gStationApi from '@gmini/sm-api-sdk/lib/gStationConnect/gStationConnect'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import { createInspectionService } from '../inspection-service'

import * as api from '../api'

import { gStationDocumentManagementUrl } from '../config'

import { notificationService } from './notificationService'

export const inspectionService = createInspectionService({
  name: 'inspectionService',
})

forward({
  from: notificationService.message,
  to: inspectionService.notification,
})

export const inspectionFetch = new FetchService({
  baseUrl: '/api/field-inspection',
  refreshTokenFunc,
})

export const checklistFetch = new FetchService({
  baseUrl: '/api/field-checklists',
  refreshTokenFunc,
})

export const checklistManagerFetch = new FetchService({
  baseUrl: '/api/checklists',
  refreshTokenFunc,
})

export const issueManagerFetch = new FetchService({
  baseUrl: '/api/issues',
  refreshTokenFunc,
})

export const authApi = new FetchService({ baseUrl: '/api/auth/v1' })

// Estimation connect
export const estimationFetch = new FetchService({
  baseUrl: '/api/estimations',
  refreshTokenFunc,
})

export const gStation = new FetchService({
  baseUrl: gStationDocumentManagementUrl,
})

estApi.estimationConnect(estimationFetch)

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [
        inspectionFetch,
        authApi,
        checklistFetch,
        checklistManagerFetch,
        estimationFetch,
        issueManagerFetch,
        gStation,
      ],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([
      inspectionFetch,
      authApi,
      checklistFetch,
      checklistManagerFetch,
      estimationFetch,
      issueManagerFetch,
      gStation,
    ])
  }
})

smApi.authConnect(authApi)
api.inspectionConnect(inspectionFetch)
api.checklistConnect(checklistFetch)
chmApi.checklistManagerConnect(checklistManagerFetch)
ismApi.issueManagerConnect(issueManagerFetch)
gStationApi.gStationConnect(gStation)
