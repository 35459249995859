import { memo, useEffect, useState } from 'react'

import { useStore } from 'effector-react'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import * as api from '../../api'

import { inspectionService } from '../../services/inspectionService'
import { useCurrentInspectionParams } from '../CurrentInspection'

import { InspectionEditorPage } from './InspectionEditorPage'

api.Inspection.getMostRecent.doneData.watch(({ classifierId }) => {
  smApi.UserClassifier.getMostRecent.defaultContext.submit({
    id: classifierId,
  })
})

export const InspectionEditorPageWrap = memo(() => {
  const currentInspection = useStore(
    inspectionService.inspection.currentInspection$,
  )
  const readonlyMode = !!currentInspection?.readOnly
  const params = useCurrentInspectionParams()
  const [resourceNotFound, setResourceNotFound] = useState(false)

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
    if (params) {
      api.Inspection.getMostRecent.defaultContext({ id: params.id })
    }
  }, [params])

  useEffect(() => {
    const subscription = api.Inspection.getMostRecent.defaultContext.failData.watch(
      ({ res }) => {
        const { errorCode } = res.data.data
        if (errorCode === 'RESOURCE_NOT_FOUND') {
          setResourceNotFound(true)
        }
      },
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [params])

  if (resourceNotFound) {
    return <Page404 />
  }

  if (!params || !currentInspection) {
    return null
  }

  return (
    <ReadonlyModeProvider
      value={{
        enabled: readonlyMode,
        description: readonlyMode ? 'Расчет находится в режиме просмотра' : '',
      }}
    >
      <InspectionEditorPage
        currentInspection={currentInspection}
        isCurrentVersion={true}
      />
    </ReadonlyModeProvider>
  )
})

InspectionEditorPageWrap.displayName = 'InspectionEditorPageWrap'
