import { PlusCircle } from '@gmini/ui-kit'
import { ChangeEventHandler } from 'react'

import { Wrapper, Input, Label } from './AttachFile.styled'

type AttachFileProps = {
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  buttonText?: string
  variant?: 'default' | 'blue'
}

export const AttachFile = ({
  onChange,
  disabled,
  buttonText = 'Прикрепить',
  variant = 'default',
}: AttachFileProps) => (
  <Wrapper>
    <Label htmlFor='file' disabled={disabled} variant={variant}>
      <PlusCircle />
      {buttonText}
    </Label>
    <Input
      multiple={true}
      id='file'
      type='file'
      name='file'
      onChange={event => {
        onChange?.(event)
        event.currentTarget.value = ''
      }}
    />
  </Wrapper>
)
