import { createStore } from 'effector'
import { clone } from 'ramda'

import * as ismApi from '@gmini/ism-api-sdk'

import { deleteFile, startUploadFile } from './file.store'

import {
  changeStatus,
  fetchMostRecentGTechIssue,
  updateGTechIssue,
} from './gTechIssue.action'

export type GTechIssuePopulated = ismApi.GTechIssue.IssuePopulated

export type RenderIssueType = {
  id: number
  version?: number
  status: ismApi.IssueStatus.Enum
  name: string
  url?: string | null
  createdAt: string | undefined
  description?: string | null
  locationDescription?: string | null
  targetUrn?: string | null
  assigneeUserId?: string | null
  assigneeRoleId?: string | null
  issueTypeId?: number | string | null
  allowedActions: GTechIssuePopulated['allowedActions']
  deleted?: boolean
}

type ById = {
  [id: string]: RenderIssueType | undefined
}

export const IssueById$ = createStore<ById>({})
  .on(
    [
      fetchMostRecentGTechIssue.doneData,
      startUploadFile.doneData,
      changeStatus.doneData,
      updateGTechIssue.doneData,
    ],
    (state, result) => {
      if (result) {
        const next = clone(state)

        next[result.id] = result
        return next
      }
      return state
    },
  )
  .on([deleteFile.doneData], (state, result) => {
    const next = clone(state)

    const issue = next[result.issueId]

    if (issue) {
      const newIssue: RenderIssueType = {
        ...issue,
        version: result.issueVersion,
      }

      next[result.issueId] = newIssue

      return next
    }

    return state
  })
