import { ReactNode, useRef } from 'react'

import {
  Container,
  Content,
  Title,
  CollapseButton,
  ContentWrapper,
} from './Accordion.styled'

export type AccordionProps = {
  children?: ReactNode
  title?: ReactNode
  open: boolean
  onToggle: () => void
}

export const Accordion = ({
  children,
  title,
  open,
  onToggle,
}: AccordionProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const height = contentRef?.current?.clientHeight || 0

  return (
    <Container open={open}>
      <Title onClick={onToggle}>
        <CollapseButton color='#A2A3B7' open={open} />
        {title}
      </Title>
      <ContentWrapper open={open} height={height}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </Container>
  )
}
