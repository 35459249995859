import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: max-content;
  height: max-content;
`

type LabelProps = {
  disabled?: boolean
  variant: 'default' | 'blue'
}

export const Label = styled.label`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  padding: 6px 8px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;

  & svg {
    max-width: 20px;
    max-height: 20px;
  }

  ${(props: LabelProps) =>
    props.disabled &&
    css`
      && {
        background: rgba(53, 59, 96, 0.05);
        pointer-events: none;
        color: rgba(53, 59, 96, 0.2);

        path {
          fill: rgba(53, 59, 96, 0.2);
        }
      }
    `}

  ${(props: LabelProps) =>
    props.variant === 'default'
      ? css`
          color: #4c5ecf;

          & path {
            fill: #4c5ecf;
            transition: background-color 0.2s ease-out, color 0.2s ease-out;
          }

          &:hover {
            background: rgba(53, 59, 96, 0.1);
          }

          &:active {
            background: rgba(53, 59, 96, 0.2);
          }
        `
      : props.variant === 'blue'
      ? css`
          color: #ebebef;
          background: #353b60;

          & path {
            fill: #ebebef;
          }

          &:hover {
            background: #00003d;
          }

          &:active {
            background: #353b60;
          }
        `
      : ''}
`

export const Input = styled.input`
  display: none;
`
