import { combine, forward, merge, sample } from 'effector'

import { prop } from 'ramda'

import {
  isApiFlatNode,
  resetChecked,
  updateChecked,
  ViewerCheckedMap,
} from '@gmini/common/lib/classifier-editor'

import { createDependencyModelUpdatedEvent } from '@gmini/common/lib/classifier-editor/dependencyModelUpdatedEvent'

import * as smApi from '@gmini/sm-api-sdk'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { notificationService } from '../../../services/notificationService'

import { dependencyTreeModel } from '../model/dependencyTreeModel'
import {
  dependencyCheckedModel,
  editorCheckedModel,
} from '../model/checkedModel'
import { classifierService } from '../../../services/classifierService'

dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    editorCheckedModel.resetChecked()
  }
})

editorCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    dependencyCheckedModel.resetChecked()
  }
})

const notification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

merge([
  smApi.BimReference.moveRefs.defaultContext.done,
  smApi.UserClassifierGroup.createGroupReference.defaultContext.done,
  smApi.BimReference.createRefs.defaultContext.done,
  smApi.BimReference.remove.defaultContext.done,
  smApi.UserClassifier.removeAll.defaultContext.done,
]).watch(() => {
  dependencyCheckedModel.resetChecked()
  editorCheckedModel.resetChecked()
})

const updated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))

forward({
  from: sample(
    currentUserClassifier$,
    updated.filter({ fn: smApi.BaseClassifier.is }),
    (currentUserClassifier, sourceClassifier) => ({
      currentUserClassifier,
      sourceClassifier,
    }),
  ).filterMap(({ currentUserClassifier, sourceClassifier }) => {
    if (
      currentUserClassifier?.sourceClassifiers.some(
        item =>
          item.type === 'BaseClassifierNode' && item.id === sourceClassifier.id,
      )
    ) {
      return currentUserClassifier
    }
  }),
  to: smApi.getSourceClassifiersVersions.submit,
})

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
dependencyCheckedModel.checked$.updates.watch(checkedMap => {
  if (Object.keys(checkedMap).length > 0) {
    const checkedMap = combine(
      {
        tree: dependencyTreeModel.flatTree$,
        checked: dependencyCheckedModel.checked$,
      },
      ({ checked, tree }) => {
        const keys = Object.keys(checked)

        return tree
          .filter(isApiFlatNode)
          .filter(item =>
            keys.some(k => item.path.join(':') === k && checked[k]),
          )
          .reduce(
            (acc, { ref }) =>
              ref
                ? {
                    ...acc,
                    [ref.type + ref.id]: ref,
                  }
                : acc,
            {} as ViewerCheckedMap,
          )
      },
    )
    updateChecked({
      path: 'Source',
      checkedMap: checkedMap.getState(),
    })
  } else {
    resetChecked('Source')
    resetShowMode()
  }
})

const modelDependencyUpdatedEvent = createDependencyModelUpdatedEvent({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  notification,
})

modelDependencyUpdatedEvent.watch(({ clsId, clsVersion, modelFromEvent }) => {
  smApi.getSourceClassifiersVersions.submit({
    id: clsId,
    version: clsVersion,
  })
})
modelDependencyUpdatedEvent.watch(({ clsId, clsVersion }) => {
  smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext.submit(
    {
      id: clsId,
      version: clsVersion,
    },
  )
})
