import * as ismApi from '@gmini/ism-api-sdk'
import * as chmApi from '@gmini/chm-api-sdk'

import { ValidationRules } from '../../../../hooks'

export const maxCountFiles = 20
export const maxCountErrorMessage = `К замечанию можно прикрепить не более ${maxCountFiles} файлов.`
export const maxSizeFileMB = 20
export const maxSizeFile = maxSizeFileMB * 1024 * 1024

export const allowExtensionFiles = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'pdf',
  'xls',
  'xlsx',
  'txt',
  'doc',
  'docx',
]

export const allowPhotoExtensionFiles = ['jpeg', 'jpg', 'png', 'gif']

export const fileValidationRules: ValidationRules = {
  checkCount: {
    maxCount: maxCountFiles,
    message: (files: File[]) =>
      `${maxCountErrorMessage} ${
        files.length === 1 ? 'Файл' : 'Файлы'
      }:  ${getFilesNameStr(files)} были исключены.`,
  },
  checkExtension: {
    whiteListExtension: allowExtensionFiles,
    message: (files: File[]) =>
      `${files.length === 1 ? 'Файл' : 'Файлы'}: ${getFilesNameStr(files)} ${
        files.length === 1 ? 'имеет' : 'имеют'
      } недопустимое расширение. Список допустимых расширений: ${allowExtensionFiles.join(
        ', ',
      )}.`,
  },
  checkSize: {
    maxSize: maxSizeFile,
    message: (files: File[]) =>
      `${files.length === 1 ? 'Файл' : 'Файлы'}: ${getFilesNameStr(files)} ${
        files.length === 1 ? 'превышает' : 'превышают'
      } максимальный размер в ${maxSizeFileMB}МБ.`,
  },
}

const getFilesNameStr = (files: File[]) =>
  files.map(({ name }) => `"${name}"`).join(', ')

export const isImage = (
  file: ismApi.GTechIssue.File | chmApi.Instance.SectionItemFile,
) =>
  allowPhotoExtensionFiles.some(
    extension => extension === file?.name.split('.').pop()?.toLowerCase(),
  )
