import { createTreeModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/treeModel'

import * as smApi from '@gmini/sm-api-sdk'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import { classifierService } from '../../../services/classifierService'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'

export const treeModel = createTreeModel({
  currentUserClassifier$,
  nodes$: classifierService.nodes$,
  onCreatedNode: smApi.UserClassifierGroup.create.done,
  dynamicMode$: dynamicGroupMode$,
})
