import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'

import {
  GTechLogo,
  LinkButtonBlue,
  UploadedFile,
  Tooltip,
  LongText,
  SelectByGroups,
} from '@gmini/ui-kit'

import moment from 'moment'

import { useStore, useStoreMap } from 'effector-react'

import { FileViewer } from '@gmini/common'
import { assertNever } from '@gmini/utils'

import * as ismApi from '@gmini/ism-api-sdk'
import * as chmApi from '@gmini/chm-api-sdk'

import {
  Row,
  RowLabel,
  RowValue,
  Footer,
  StatusIndicator,
} from '../Checklists/RenderChecklist.styled'

import { Accordion } from '../Accordion'

import { InspectionIssue } from '../../../../api'

import { useFilesValidation } from '../../../../hooks'

import { envLinks } from '../../../../config'

import { buildUrlWithParams, getIssuesManagerLink } from '../../../../helpers'

import { FileValidationErrorsPopup } from '../FileValidationErrorsPopup'

import {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeUserList$,
} from '../../model/assigneeGroupList'

import { fetchUserListPending$ } from '../../model/user.store'

import { fetchRoleListPending$ } from '../../model/role.store'

import { AssigneeListItem } from '../../../../services/createAssigneeGroupListService'

import { useAssignees } from '../../common/useAssignees'

import { RenderIssueType } from './renderIssue.store'

import {
  LabelAccordion,
  Title,
  Container,
  UploadedFilesWrapper,
  AttachedFilesWrapper,
  StatusValue,
  StatusWrapper,
  Description,
} from './RenderIssue.styled'
import {
  changeStatusPending$,
  updateGTechIssuePending$,
  updateGTechIssue,
  uploadFiles,
} from './gTechIssue.action'

import {
  deleteFile,
  deleteFilePending$,
  downloadFile,
  fetchContentFilesPending$,
  getFilePending$,
  fileLinkById$,
  startUploadFilePending$,
  addFilePending$,
} from './file.store'
import { AttachFile } from './AttachFile'
import {
  fileValidationRules,
  isImage,
  maxCountErrorMessage,
  maxCountFiles,
} from './fileValidationRules'
import {
  issueStatusList$,
  issueTypes$,
  PreparedIssueStatus,
} from './gTechIssue.store'
import { getAssigneeFromIssue } from './getAssigneeFromIssue'

export type RenderIssueProps = {
  issueData: RenderIssueType
  button: React.ReactNode
  renderStatus: ((status: PreparedIssueStatus) => JSX.Element) | null
  type: InspectionIssue['type']
  fileList: ismApi.GTechIssue.File[] | null
  projectUrn: string
}

export const RenderIssue = ({
  issueData,
  button,
  renderStatus,
  type,
  fileList,
  projectUrn,
}: RenderIssueProps) => {
  const issuesManagerLink = useMemo(() => getIssuesManagerLink(envLinks), [])

  const {
    name,
    status,
    createdAt,
    description,
    locationDescription,
    targetUrn,
    id,
    version,
    issueTypeId,
  } = issueData
  const [openAccordion, setOpenAccordion] = useState(true)
  const [
    openedFileValidationErrorsPopup,
    setOpenedFileValidationErrorsPopup,
  ] = useState(false)
  const getFilePending = useStore(getFilePending$)
  const fileLinkById = useStore(fileLinkById$)
  const startUploadFilePending = useStore(startUploadFilePending$)
  const addFilePending = useStore(addFilePending$)

  const [openFilesViewer, setOpenFilesViewer] = useState(false)
  const [viewerImageCurrentIdx, setViewerImageCurrentIdx] = useState(0)
  const assigneeGroupList = useStore(assigneeGroupList$)
  const fetchUserListPending = useStore(fetchUserListPending$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)
  const deleteFilePending = useStore(deleteFilePending$)
  const fetchContentFilesPending = useStore(fetchContentFilesPending$)
  const changeStatusPending = useStore(changeStatusPending$)
  const updateGTechIssuePending = useStore(updateGTechIssuePending$)

  const issueTypes = useStore(issueTypes$)
  const issueTypeName = useMemo(
    () => issueTypes.find(({ id }) => issueTypeId === id)?.name || '',
    [issueTypes, issueTypeId],
  )

  const issueStatus = useStoreMap({
    store: issueStatusList$,
    keys: [status],
    fn: (issueStatusList, [currentStatus]) =>
      issueStatusList.find(({ status }) => status === currentStatus) || null,
  })

  let openIssueLink = null

  if (issuesManagerLink) {
    openIssueLink = (
      <LinkButtonBlue
        href={buildUrlWithParams(`${issuesManagerLink}/`, {
          issueId: issueData.id,
          projectUrn,
        })}
        target='_blank'
      >
        Открыть в G-tec Менеджер Замечаний
      </LinkButtonBlue>
    )
  }

  const imgList = fileList?.filter(isImage)
  const imgLinkList = imgList?.map(({ id }) => fileLinkById[id]).filter(Boolean)

  const {
    fileErrorMessages,
    validateFiles,
    clearFileErrors,
  } = useFilesValidation({
    rules: fileValidationRules,
    onError: () => setOpenedFileValidationErrorsPopup(true),
  })

  const getAssignees = useAssignees({ assigneeRoleList$, assigneeUserList$ })
  const assigneeIssue = getAssigneeFromIssue(issueData)
  const [assignee] = getAssignees(assigneeIssue ? [assigneeIssue] : [])

  const onChangeAssignee = useCallback(
    (value: AssigneeListItem | null) => {
      if (typeof id !== 'number' || typeof version !== 'number') {
        throw new Error("Can't update issue without id and version")
      }

      const params: ismApi.GTechIssue.UpdateParams = {
        id,
        version,
      }

      if (value === null && issueData.assigneeRoleId) {
        params.assigneeRoleId = null
      }

      if (value === null && issueData.assigneeUserId) {
        params.assigneeUserId = null
      }

      switch (value?.source) {
        case chmApi.AssigneeSource.USER: {
          if (value.id === issueData?.assigneeUserId) {
            return
          }

          params.assigneeUserId = value.id || null
          break
        }
        case chmApi.AssigneeSource.ROLE: {
          if (value.id === issueData?.assigneeRoleId) {
            return
          }

          params.assigneeRoleId = value.id || null
          break
        }
        default: {
          if (value) {
            return assertNever('Unexpected source', value.source)
          }
        }
      }

      updateGTechIssue(params)
    },
    [id, issueData?.assigneeRoleId, issueData?.assigneeUserId, version],
  )

  const onUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files
    if (!files || typeof id !== 'number' || !version) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), fileList?.length)

    if (allowFiles.length) {
      await uploadFiles({ issueId: id, issueVersion: version }, allowFiles)
    }
  }

  const onDeleteFile = (fileId: number) => {
    if (typeof id === 'number' && version) {
      deleteFile({ fileId, issueId: id, issueVersion: version })
    }

    clearFileErrors()
  }

  const onDownloadFile = (fileId: number) => {
    downloadFile({ fileId })
    clearFileErrors()
  }

  const isMaxCountFiles = Number(fileList?.length) >= maxCountFiles

  const onClickFile = (file: ismApi.GTechIssue.File) => {
    if (!isImage(file)) {
      return
    }
    const imgIdx = imgList?.indexOf(file) || 0
    setViewerImageCurrentIdx(imgIdx)
    setOpenFilesViewer(true)
  }

  return (
    <>
      <FileValidationErrorsPopup
        open={openedFileValidationErrorsPopup}
        onClose={() => setOpenedFileValidationErrorsPopup(false)}
        errors={fileErrorMessages}
      />
      <Container>
        <Accordion
          onToggle={() => setOpenAccordion(prev => !prev)}
          open={openAccordion}
          title={
            <LabelAccordion>
              <Title>
                {openAccordion ? (
                  name
                ) : (
                  <LongText partSize={20} withoutRightText>
                    {name}
                  </LongText>
                )}
              </Title>
              {issueStatus && !openAccordion && (
                <StatusWrapper>
                  <StatusIndicator color={issueStatus.color} />
                  <StatusValue>{issueStatus.name}</StatusValue>
                </StatusWrapper>
              )}
            </LabelAccordion>
          }
        >
          <Row>
            <RowLabel>Источник</RowLabel>

            <RowValue>
              <GTechLogo />
            </RowValue>
          </Row>

          {issueStatus && (
            <Row>
              <RowLabel>Статус</RowLabel>

              {renderStatus ? (
                renderStatus(issueStatus)
              ) : (
                <RowValue bold>
                  <StatusIndicator color={issueStatus?.color} />
                  {issueStatus.name}
                </RowValue>
              )}
            </Row>
          )}
          {createdAt && (
            <Row>
              <RowLabel>Дата создания</RowLabel>
              <RowValue>{moment(createdAt).format('LL')}</RowValue>
            </Row>
          )}
          <Row>
            <RowLabel>Назначено на</RowLabel>
            <RowValue bold>
              <SelectByGroups
                onChange={onChangeAssignee}
                value={assignee}
                placeholder='Выберите ответственного'
                groups={assigneeGroupList}
                getOptionLabel={value => value.label}
                colorScheme='white'
                emptyOptionListMessage='Нет доступных совпадений'
                disabled={
                  startUploadFilePending ||
                  deleteFilePending ||
                  changeStatusPending ||
                  fetchUserListPending ||
                  fetchRoleListPending ||
                  updateGTechIssuePending
                }
              />
            </RowValue>
          </Row>
          {description && (
            <Row>
              <RowLabel>Описание</RowLabel>
              <RowValue bold>
                <Description>{description}</Description>
              </RowValue>
            </Row>
          )}
          {targetUrn && (
            <Row>
              <RowLabel>Связанный документ</RowLabel>
              <RowValue>Присутствует</RowValue>
            </Row>
          )}
          {locationDescription && (
            <Row>
              <RowLabel>Локация</RowLabel>
              <RowValue bold>{locationDescription}</RowValue>
            </Row>
          )}
          {issueTypeName && (
            <Row>
              <RowLabel>Дисциплина</RowLabel>
              <RowValue bold>{issueTypeName}</RowValue>
            </Row>
          )}

          <Row>
            <RowLabel>Документы</RowLabel>
            {imgLinkList && imgLinkList?.length !== 0 && (
              <FileViewer
                linkList={imgLinkList}
                open={openFilesViewer}
                setOpen={setOpenFilesViewer}
                startIndex={viewerImageCurrentIdx}
              />
            )}
            <RowValue>
              <AttachedFilesWrapper>
                {fileList && fileList.length !== 0 && (
                  <UploadedFilesWrapper>
                    {fileList.map(file => (
                      <UploadedFile
                        key={file.s3Key}
                        title={file.name || ''}
                        onDelete={() => onDeleteFile(file.id)}
                        onDownload={() => onDownloadFile(file.id)}
                        onClick={() => onClickFile(file)}
                        disabled={
                          deleteFilePending ||
                          fetchContentFilesPending ||
                          startUploadFilePending ||
                          getFilePending
                        }
                      />
                    ))}
                  </UploadedFilesWrapper>
                )}

                <Tooltip
                  title={isMaxCountFiles ? maxCountErrorMessage : ''}
                  styleContent={{
                    width: 'min-content',
                  }}
                  noMaxWidth
                >
                  <AttachFile
                    disabled={
                      startUploadFilePending ||
                      addFilePending ||
                      fetchContentFilesPending ||
                      Number(fileList?.length) >= 20 ||
                      deleteFilePending
                    }
                    onChange={onUploadFile}
                  />
                </Tooltip>
              </AttachedFilesWrapper>
            </RowValue>
          </Row>
        </Accordion>

        {openAccordion && (
          <Footer>
            {openIssueLink}
            {button}
          </Footer>
        )}
      </Container>
    </>
  )
}
