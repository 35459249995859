import { createStore, merge, restore, sample } from 'effector'
import { clone } from 'ramda'

import * as chmApi from '@gmini/chm-api-sdk'

import { resetChecklistTreeStatuses } from '../../model/checklist-status'
import { templateTypes$ } from '../templateTypes'

export type InstancePopulated = chmApi.Instance.InstancePopulated

export const fetchChecklistItem = chmApi.Instance.fetchMostRecent.createContext()
export const updateInstance = chmApi.Instance.update.createContext()
export const updateInstancePending$ = updateInstance.pending$
export const fetchMostRecentInstance = chmApi.Instance.fetchMostRecent.createContext()

export const fetchTemplateItem = chmApi.Template.fetchTemplateMostRecent.createContext()

export const instanceEvent = merge([
  updateInstance.doneData,
  fetchMostRecentInstance.doneData,
])

export type ChecklistData = InstancePopulated & {
  templateTypeDescription?: string
  completedItemsCount: number
  totalQuestions?: number
  isCompletedSection?: boolean
  totalItems?: number
  completedSectionCount?: number
  sectionsCount?: number
}

type ChecklistById = {
  [id: string]: ChecklistData | undefined
}

fetchChecklistItem.doneData.watch(({ templateId }) => {
  if (templateId) {
    fetchTemplateItem({ id: templateId })
  }
})

instanceEvent.watch(({ id, templateId }) => {
  fetchChecklistItem({ id })
  if (templateId) {
    fetchTemplateItem({ id: templateId })
  }
  resetChecklistTreeStatuses()
})

const fetchTemplateItemWithTemplateTypes = sample({
  clock: fetchTemplateItem.doneData,
  source: templateTypes$,
  fn: (templateTypes, templateItem) => ({
    ...templateItem,
    templateTypeDescription: templateTypes.find(
      templateType => templateType.id === templateItem.templateTypeId,
    )?.name,
  }),
})

export const templateItemWithTemplateTypes$ = restore(
  fetchTemplateItemWithTemplateTypes,
  null,
)

export const checklistById$ = createStore<ChecklistById>({})
  .on(fetchChecklistItem.doneData, (state, result) => {
    const next = clone(state)

    next[result.id] = {
      ...next[result.id],
      ...result,
      completedItemsCount: result.itemProperties.length,
    }

    return next
  })
  .on(fetchTemplateItemWithTemplateTypes, (state, result) => {
    const next = Object.fromEntries(
      Object.entries(state).map(([checklistId, checklist]) => {
        if (checklist?.templateId === result.id) {
          const totalQuestions =
            result?.sections?.reduce(
              (acc, item) => (item.items ? acc + item.items.length : acc),
              0,
            ) || 0
          const isCompletedSection =
            checklist.completedItemsCount === totalQuestions &&
            checklist.completedItemsCount !== 0 //TODO Добавить поддержку нескольких секций, когда создание нескольких секций будет возможно

          return [
            checklistId,
            {
              ...checklist,
              templateTypeDescription: result.templateTypeDescription,
              totalQuestions,
              isCompletedSection,
              totalItems: totalQuestions,
              completedSectionCount: Number(isCompletedSection),
              sectionsCount: result?.sections?.length || 0,
            },
          ]
        }

        return [checklistId, checklist]
      }),
    )

    return next
  })
