import styled, { css } from 'styled-components'

import { CrossedChain as CrossedChainInit } from '@gmini/ui-kit'

export const Container = styled.div`
  height: 100%;
  font-family: Roboto, sans-serif;
`

export const Link = styled.a`
  text-decoration: none;
`

export const TableData = styled.td`
  padding: 0 6px 6px 6px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #000335;

  &:not(:last-child):not(:first-child) {
    border: 1px solid #e2e4e9;
    border-bottom: none;
    border-top: none;
  }
`

export const TableHead = styled.th`
  font-weight: 700;
  padding: 6px 6px 2px 6px;
  color: #a2a3b7;
  letter-spacing: 0.15px;
  font-size: 12px;
  line-height: 30px;
  width: 33%;

  &:not(:last-child):not(:first-child) {
    border: 1px solid #e2e4e9;
    border-bottom: none;
    border-top: none;
  }
`

export const Title = styled.div`
  color: #00003d;
  margin-bottom: 21px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 5.5px 0 5px 12px;

  &:nth-child(odd) {
    background: rgba(244, 244, 248, 0.5);
  }
`

export const RowLabel = styled.div`
  font-weight: bold;
  flex: 1;
  padding-right: 24px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #a2a3b7;
`

type RowValueProps = {
  bold?: boolean
  flex?: number
}

export const RowValue = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  font-weight: ${(props: RowValueProps) => (props.bold ? 700 : 400)};
  color: #000335;
  font-size: 14px;
  word-break: break-word;

  ${(props: RowValueProps) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`

export const Table = styled.table`
  width: 100%;
  padding: 0;
  background: none;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  background-image: none;
  margin-top: 23px;

  tr:nth-child(odd) {
    td {
      background: rgba(244, 244, 248, 0.5);
    }
  }
`

type StatusIndicatorProps = {
  color?: string
}

export const StatusIndicator = styled.div`
  height: 18px;
  width: 4px;
  margin-right: 5px;
  border-radius: 10px;
  background-color: ${(props: StatusIndicatorProps) => props.color};
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`

type EmptyTitleProps = {
  mt?: string
}

export const EmptyTitle = styled.div`
  font-weight: 600;
  padding-right: 10px;
  margin-bottom: 10px;

  ${(props: EmptyTitleProps) =>
    css`
      margin-top: ${props.mt};
    `}
`

export const DeletedTitle = styled(EmptyTitle)`
  color: red;
`

export const CrossedChain = styled(CrossedChainInit)`
  margin-right: 3px;
`
